import create from 'zustand';
import { persist } from 'zustand/middleware'

import { StoreApp, PushApp, DefaultStoreApp, PushStatus } from 'types/store';

export const default_app_store: DefaultStoreApp = {
  language: 'fr',
  is_logged_in: false,
  registration: undefined,
  inbox_notifications: 0,
  device_id: undefined,
  online: true
};

export const useAppStore = create<StoreApp>()(persist(set => ({
  ...default_app_store,
  logIn: () => set({ is_logged_in: true }),
  logOut: () => set({ is_logged_in: false, inbox_notifications: 0 }),
  setAppLanguage: (language: 'fr' | 'en') => set({ language }),
  storeSWRegistration: (registration) => set({ registration }),
  setDeviceId: device_id => set({ device_id }),
  setOnline: online => set({ online })
}), { name: 'app-store' }));

export const usePushStore = create<PushApp>()(persist(set => ({
  push_status: 'pristine' as PushStatus,
  setPushStatus: push_status => set({ push_status }),
}), {
  name: 'push-store',
  getStorage: () => sessionStorage
}));
