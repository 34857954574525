import create from 'zustand';
import { persist } from 'zustand/middleware';

import { Organisation } from 'types/organisation';

export const default_organisation: Organisation = {
  id: 0,
  children: [],
  Children: [],
  algolia_key: '',
  name: '',
  real_name: '',
  logo_url: '',
  tva_number: '',
  hourly_price: 85,
  hourly_price_2: 105,
  hourly_price_3: 135,
  travel_price: 55,
  travel_price_2: 75,
  travel_price_3: 95,
  billing_type: 'per-task',
  subscription_type: 'regular',
  verification_status: 'verified',
  terms_status: 'accepted',
  activity_area: 'other',
  billing_name: '-',
  billing_address: '-',
  billing_emails: [],
  billing_city: '-',
  billing_postcode: '-',
  siret_number: '',
  client_sellsy_id: 0,
  public_key: '',
  type: 'client',
  siblings: [],
  OrganisationUsers: [],
  Addresses: [],
  can_order: true,
  invoice_stats: {
    price_due: 0,
    price_late: 0,
    total_due: 0,
    total_late: 0,
  },
  has_valid_payment_method: false,
  has_to_accept_terms: true,
  terms_accepted_at: undefined,
};

type StoreOrganisation = Organisation & {
  setOrganisation: (organisation: Organisation) => void;
  reset: () => void;
  acceptTerms: () => void;
  refuseTerms: () => void;
  updateHasValidPaymentMethod: (has_valid_payment_method: boolean) => void;
  updateHasToAcceptTerms: (has_to_accept_terms: boolean) => void;
};

export const useOrganisationStore = create<StoreOrganisation>()(
  persist(
    set => ({
      ...default_organisation,
      setOrganisation: orga => set(state => ({ ...state, ...orga })),
      reset: () => set({ ...default_organisation }),
      acceptTerms: () => set({ terms_status: 'accepted' }),
      refuseTerms: () => set({ terms_status: 'refused' }),
      updateHasValidPaymentMethod: (has_valid_payment_method: boolean) => set({ has_valid_payment_method }),
      updateHasToAcceptTerms: (has_to_accept_terms: boolean) => set({ has_to_accept_terms }),
    }),
    { name: 'organisation-store' },
  ),
);
