import * as React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import styles from './base-modal.module.css';

Modal.setAppElement('#root');

export type MJBaseModalProps = {
  children: React.ReactNode,
  prevent_close_from_blur?: boolean,
  behind_panel?: boolean
  onClose?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void
}

const MJBaseModal = ({ prevent_close_from_blur, onClose, behind_panel, children }: MJBaseModalProps) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setMounted(true), 100);
  }, []);

  return <Modal
    isOpen={true}
    onRequestClose={prevent_close_from_blur ? () => { } : onClose}
    role="dialog"
    parentSelector={() => (document.getElementById('root')!)}
    overlayClassName={classNames({
      [styles['overlay-modal']]: true,
      [styles['overlay-modal-behind-panel']]: behind_panel
    })}
    className={classNames({
      [styles['modal-window']]: true,
      [styles['modal-window-animated']]: mounted,
      [styles['modal-window-behind-panel']]: behind_panel
    })}>
   {children}
  </Modal>
};

export default MJBaseModal;
