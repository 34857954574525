import * as React from 'react';
import classNames from 'classnames';

import AltPanel, { AltPanelProps } from './alt-panel';
import FinalPanel, { FinalPanelProps } from './final-panel';

import styles from './animated-panel.module.css';

export type AnimatedPanelProps = {
  is_panel_visible: boolean,
  type?: 'alt' | 'final'
} & AltPanelProps & Pick<FinalPanelProps, 'link' | 'with_header_shadow'>;

const AnimatedPanel = ({
  title, title_icon, sections, is_panel_visible,
  with_header_shadow, type = 'alt', link, closePanel
}: AnimatedPanelProps) => (
  <React.Fragment>
    <div className={classNames({
      [styles['panel-background']]: is_panel_visible,
      [styles['panel-background-hidden']]: !is_panel_visible
    })} onClick={closePanel}>
    </div>
    <div className={classNames({
      [styles['panel-wrapper']]: true,
      [styles['panel-wrapper-visible']]: is_panel_visible
    })}>
      { type === 'alt'
        ?
        <AltPanel
          title={title}
          title_icon={title_icon}
          sections={sections}
          closePanel={closePanel} />
        :
        <FinalPanel
          title={title}
          body={sections as React.ReactNode}
          link={link}
          is_panel_visible={is_panel_visible}
          with_header_shadow={with_header_shadow}
          closePanel={closePanel!} />
      }
    </div>
  </React.Fragment>
);

export default AnimatedPanel;
