import styles from './mailto.module.css';

type MailtoProps = {
  mail: string,
  text?: string,
}

const Mailto = ({ mail, text }: MailtoProps) => (
  <a className={styles['mail-to']} href={`mailto:${mail}`}>{text || mail}</a>
);

export default Mailto;
