import * as React from 'react';
import { Dialog } from '@capacitor/dialog';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import semverGt from 'semver/functions/gt';

import AppConfig from 'config';

import packageInfo from '../../../package.json';

type Init = {
  minimum_version: string;
  current_version: string;
};

export default function useVersionCheck(is_logged_in: boolean) {
  const showNewUpdateModal = React.useCallback(async () => {
    const { value } = await Dialog.confirm({
      title: 'Merci Jack',
      message: 'Une nouvelle version est disponible sur le store !',
      okButtonTitle: 'Voir',
      cancelButtonTitle: 'Fermer',
    });

    if (value === true) {
      window.open(AppConfig.android.playstore_url, '_blank');
    }
  }, []);

  const showForceUpdateModal = React.useCallback(async () => {
    const { value } = await Dialog.confirm({
      title: 'Merci Jack',
      message: 'Une nouvelle version plus récente est requise, merci de mettre à jour votre application.',
      okButtonTitle: 'Mettre à jour',
      cancelButtonTitle: '',
    });

    if (value === true) {
      window.open(AppConfig.android.playstore_url, '_blank');
    }
    showForceUpdateModal();
  }, []);

  React.useEffect(() => {
    const platform = Capacitor.getPlatform();

    async function fetchInit() {
      try {
        const res = await axios.get(AppConfig.android.init_url);
        const init = res.data as Init;
        const remote_minimum_version = init.minimum_version || packageInfo.version;
        const remote_current_version = init.current_version || packageInfo.version;

        if (semverGt(remote_minimum_version, packageInfo.version)) {
          return showForceUpdateModal();
        }

        if (semverGt(remote_current_version, packageInfo.version)) {
          return showNewUpdateModal();
        }
        return undefined;
      } catch (err) {
        console.error(err);
      }
    }

    if (platform === 'android' && is_logged_in) {
      fetchInit();
    }
  }, [is_logged_in, showNewUpdateModal, showForceUpdateModal]);
}
