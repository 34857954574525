import moment from 'moment';
import axios from 'axios';

import config from 'config';
import { request } from 'api/request';
import { default_organisation, useOrganisationStore } from 'components/hooks/stores/organisation';
import { useUserStore } from 'components/hooks/stores/user';
import { useJackStore } from 'components/hooks/stores/jack';
import { User } from 'types/user';
import { JackInfo } from 'types/jack';
import { useAvailabilityStore } from 'components/hooks/stores/availability';
import { JackContractStatuses, JackContractTypes } from 'types/jack-contract';

export async function refreshJackMe() {
  const jack_info = await request({ path: '/jacks/me', api_version: '2' });

  localStorage.setItem('can_order_refresh', moment().add(config.can_order_refresh_delay, 'seconds').format('x'));
  const updated_user: Partial<User> = {
    first_name: jack_info.user.first_name,
    last_name: jack_info.user.last_name,
    phone_number: jack_info.user.phone_number,
    type: jack_info.user.type,
    role: jack_info.user.role,
    avatar_url: jack_info.user.avatar_url,
    terms_status: jack_info.user.terms_status,
  };
  const updated_info = jack_info.infos;
  const updated_organisation = jack_info.organisation || default_organisation;
  const updated_availability = jack_info.availability;

  useUserStore.getState().setUser(updated_user as User);
  useJackStore.getState().setJackInfos(updated_info);
  useAvailabilityStore.getState().setAvailability(updated_availability);
  useOrganisationStore.getState().setOrganisation(updated_organisation);
}

export function getJackCalendarURL({ jack_id }: { jack_id: number }) {
  return request({ path: `/users/${jack_id}/calendar/url` });
}

export function getJackById(jack_id: number) {
  return request({ path: `/jacks/${jack_id}` });
}

export function updateJackInfos({ jack_id, infos }: { jack_id: number; infos: JackInfo }) {
  return request({
    method: 'PUT',
    api_version: '2',
    path: `/jacks/${jack_id}/infos`,
    body: infos,
  });
}

export function getJackSkills(jack_id: number) {
  return request({ path: `/jacks/${jack_id}/skills` });
}

export function getJackContracts(jack_id: number, types?: JackContractTypes[], statuses?: JackContractStatuses[]) {
  let query = [];

  if (types) {
    query.push(`types=${types.join(',')}`);
  }

  if (statuses) {
    query.push(`statuses=${statuses.join(',')}`);
  }
  return request({ path: `/jacks/${jack_id}/contracts${query.length > 0 ? `?${query.join('&')}` : ''}` });
}

export function getJackMainContracts(acknowledged?: boolean) {
  let query = '';

  if (acknowledged !== undefined) {
    query = `?acknowledged=${acknowledged}`;
  }
  return request({ path: `/jacks/main-contracts${query.length > 0 ? query : ''}` });
}

export function getJackMainContractsByAddress(jack_id: number, address_id: number) {
  return request({ path: `/jacks/${jack_id}/addresses/${address_id}/contracts` });
}

export function getJackPendingInterventions() {
  return request({ path: '/interventions/pending' });
}

export async function getJackScore(fk_jack_id: number, begin_week: string, end_week: string) {
  const access_token = useUserStore.getState().access_token;
  const res = await axios.request({
    url: 'https://api-inception-dot-mj-2016.appspot.com/stats/jack_score',
    params: {
      fk_jack_id,
      begin_week,
      end_week,
    },
    headers: {
      'x-access-token': access_token,
    },
  });

  return res.data;
}

export function getJackOrganisationFees(fk_jack_id: number, fk_organisation_ids: number[]) {
  return request({ path: `/jacks/${fk_jack_id}/fees?organisation_ids=${fk_organisation_ids.join(',')}` });
}

type UpdateJackOrganisationFeesBody = {
  fk_jack_id: number;
  organisation_ids?: number[];
  fee_percentage: number;
  fee_percentage_2: number;
  fee_percentage_3: number;
  travel_fee_percentage: number;
  apply_all?: boolean;
};

export function updateJackOrganisationFees({
  fk_jack_id,
  organisation_ids,
  fee_percentage,
  fee_percentage_2,
  fee_percentage_3,
  travel_fee_percentage,
  apply_all,
}: UpdateJackOrganisationFeesBody) {
  return request({
    method: 'PUT',
    path: `/jacks/${fk_jack_id}/fees`,
    body: {
      organisation_ids,
      fee_percentage,
      fee_percentage_2,
      fee_percentage_3,
      travel_fee_percentage,
      apply_all,
    },
  });
}
