import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient } from "@tanstack/react-query";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

// IMPORTANT TO BE ON TOP
import 'typeface-montserrat';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'instantsearch.css/themes/reset.css';
import 'instantsearch.css/themes/algolia.css';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import 'animate.css/animate.min.css';
import 'rc-tooltip/assets/bootstrap.css';
import './index.css';

import { useAppStore } from 'components/hooks/stores/app';

import App from './components';
import * as serviceWorker from './register-service-worker';
import packageInfo from '../package.json';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/en-gb';

// Window extension for 3rd party
declare global {
  export interface Window {
    Cypress: boolean,
    initialState: any,
    store: any,
    RocketChat: any,
    stonlyTrack: any
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    }
  }
});

if (!window.Cypress && import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://f0c2ca58ec7340ca81de7512620f5298@o529679.ingest.sentry.io/5653333',
    release: `mj-webapp@${packageInfo.version}`, // must match release in vite.config.ts
    environment: 'production',
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes as any
      ),
      tracingOrigins: ['api.mercijack.co']
    })],
    tracesSampleRate: 0.2,
    ignoreErrors: [
      'Non-Error promise rejection',
      'Loading chunk',
      'Loading CSS chunk',
      'Unexpected end of input',
      'Failed to register a ServiceWorker for scope'
    ]
  });
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <App queryClient={queryClient} />
);

if (import.meta.env.VITE_APP_PLATFORM === 'web') {
  serviceWorker.register({
    onRegistration: (registration) => useAppStore.getState().storeSWRegistration(registration)
  });
}
// Clean old useless registrations on Android
else {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}
