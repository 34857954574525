import { default_static_ops_rules, default_dynamic_ops_rules } from './ops-rules';
import { default_static_manager_rules, default_dynamic_manager_rules } from './saas-manager-rules';
import jack_rules from './jack-rules';
import { Intervention } from 'types/intervention';

export type InterventionActionDetail = 'user_cta' | 'ops_cta' | 'jack_cta';
export type InterventionAction = `intervention:${InterventionActionDetail}`;

export type TimelineActionDetail =
  | 'availabilities'
  | 'jack_availabilities'
  | 'generate_invoice'
  | 'ops_assigned'
  | 'delete_jack'
  | 'add_slot'
  | 'client_price'
  | 'edit_contact'
  | 'close_dispute'
  | 'change_address'
  | 'view_equipments'
  | 'change_jack'
  | 'edit_service'
  | 'assign_jack'
  | 'change_date'
  | 'update_intervention'
  | 'equipments_delivery'
  | 'commission'
  | 'internal_note'
  | 'link_estimates'
  | 'edit_estimate'
  | 'update_date'
  | 'update_estimate'
  | 'remove_estimate'
  | 'estimate_validation'
  | 'sync_estimate'
  | 'pick_solution'
  | 'ops_grade'
  | 'edit_service_title'
  | 'delivery_price'
  | 'update_jack_price'
  | 'jack_grade'
  | 'order_equipments'
  | 'equipments_validation'
  | 'client'
  | 'jack_price'
  | 'delivery_info_detailed';
export type TimelineAction = `timeline:${TimelineActionDetail}`;

export type OrganisationActionDetail =
  | 'create_address'
  | 'create_user'
  | 'financial_stats'
  | 'cancel_contract'
  | 'view_sellsy_account'
  | 'management_info'
  | 'reprocess_invoice'
  | 'update_contract_task'
  | 'update_equipment'
  | 'see_invoice'
  | 'filter_contracts'
  | 'duplicate_contract'
  | 'create_contract';
export type OrganisationAction = `organisation:${OrganisationActionDetail}`;

export type ReportActionDetail = 'sign';
export type ReportAction = `report:${ReportActionDetail}`;

export type DraftActionDetail = 'transform';
export type DraftAction = `drafts:${DraftActionDetail}`;

export type PanelActionDetail =
  | 'user'
  | 'invoice'
  | 'cancel_intervention'
  | 'renew_estimate'
  | 'check_estimate'
  | 'edit_service'
  | 'dispute'
  | 'see_invoice'
  | 'cancel_ongoing'
  | 'jack'
  | 'version';
export type PanelAction = `panel:${PanelActionDetail}`;

export type DashboardActionDetail = 'create_service' | 'create_address' | 'create' | 'ops_assigned' | 'delivery' | 'tags_type';
export type DashboardAction = `dashboard:${DashboardActionDetail}`;

export type JackActionDetail = 'edit' | 'edit_skills' | 'set_availabilities' | 'copy_password' | 'see_pricing';
export type JackAction = `jacks:${JackActionDetail}`;

export type UserActionDetail = 'password';
export type UserAction = `users:${UserActionDetail}`;

export type AddressActionDetail = 'create';
export type AddressAction = `addresses:${AddressActionDetail}`;

export type HeaderActionDetail = 'trial';
export type HeaderAction = `header:${HeaderActionDetail}`;

export type CheckListActionDetail = 'ko_list';
export type CheckListAction = `check_list:${CheckListActionDetail}`;

export type EquipmentsActionDetail = 'see_prices';
export type EquipmentsAction = `equipments:${EquipmentsActionDetail}`;

export type BlacklistActionDetail = 'update';
export type BlacklistAction = `blacklist:${BlacklistActionDetail}`;

export type JackContractActionDetail = 'assign';
export type JackContractAction = `jack_contracts:${JackContractActionDetail}`;

export type PlanningActionDetail = 'use_filters' | 'display_jack_name' | 'ics';
export type PlanningAction = `planning:${PlanningActionDetail}`;

export type InboxActionDetail = 'all_conversations';
export type InboxAction = `inbox:${InboxActionDetail}`;

export type CheckReportActionDetail = 'undo' | 'report_actions';
export type CheckReportAction = `check_report:${CheckReportActionDetail}`;

export type Action =
  | TimelineAction
  | InterventionAction
  | OrganisationAction
  | ReportAction
  | DraftAction
  | PanelAction
  | DashboardAction
  | AddressAction
  | HeaderAction
  | CheckListAction
  | JackAction
  | EquipmentsAction
  | BlacklistAction
  | UserAction
  | JackContractAction
  | PlanningAction
  | InboxAction
  | CheckReportAction;

export type Actions = {
  static?: Action[];
  dynamic?: {
    [key in Action]?: (data: any) => boolean;
  };
};

export type ACL = {
  superadmin?: Actions;
  manager?: Actions;
  admin?: Actions;
  user?: Actions;
  restricted?: Actions;
};

export type ACLRules = {
  ops: ACL;
  pro: ACL;
  jack: ACL;
};

const rules: ACLRules = {
  ops: {
    superadmin: {
      static: default_static_ops_rules,
      dynamic: {
        ...default_dynamic_ops_rules!,
        'organisation:cancel_contract': ({ is_cancelable, user_id }) => {
          // prod and integ ids are merged because osef
          return is_cancelable && [6290, 6202, 6201, 99, 249].includes(user_id);
        },
      },
    },
    admin: {
      static: default_static_ops_rules,
      dynamic: default_dynamic_ops_rules,
    },
    user: {
      static: default_static_ops_rules,
      dynamic: default_dynamic_ops_rules,
    },
    restricted: {
      static: default_static_ops_rules,
      dynamic: default_dynamic_ops_rules,
    },
  },
  pro: {
    manager: {
      static: default_static_manager_rules,
      dynamic: default_dynamic_manager_rules,
    },
    admin: {
      static: [
        'dashboard:create_service',
        'dashboard:create_address',
        'addresses:create',
        'header:trial',
        'intervention:user_cta',
        'timeline:client_price',
        'organisation:create_address',
        'organisation:create_user',
        'organisation:financial_stats',
        'panel:user',
        'dashboard:tags_type',
        'check_list:ko_list',
      ],
      dynamic: {
        'timeline:edit_contact': ({ intervention }) => {
          return intervention;
        },
        'timeline:change_address': ({ intervention }) => {
          return intervention && !['ongoing', 'started', 'ended'].includes(intervention.status);
        },
        'timeline:view_equipments': ({ service }) => {
          return service && !['pending-technical-validation', 'pending-validation', 'pending-renewal'].includes(service.equipment_status);
        },
        'timeline:edit_service': ({ service }) => {
          return service && ['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
        },
        'report:sign': ({ is_signed }) => {
          return !is_signed;
        },
        'drafts:transform': ({ addresses }) => {
          return addresses && addresses.length > 0;
        },
        'panel:invoice': ({ service }) => {
          return service.invoice_sellsy_id && service.invoice_sellsy_id !== -1 && service.invoice_status !== 'draft';
        },
        'panel:cancel_intervention': ({ intervention }: { intervention: Intervention }) => {
          return (
            intervention.status === 'expired' ||
            (['pending', 'planned'].includes(intervention.status) &&
              !intervention.estimate_sellsy_id &&
              !intervention.product_estimate_sellsy_id &&
              !intervention.estimate_sinao_id &&
              !intervention.fk_quote_id)
          );
        },
        'panel:renew_estimate': ({ intervention }: { intervention: Intervention }) => {
          return (
            intervention.status === 'expired' &&
            (intervention.estimate_sellsy_id !== null || intervention.product_estimate_sellsy_id !== null || intervention.estimate_sinao_id !== null || intervention.fk_quote_id !== null)
          );
        },
        'panel:check_estimate': ({ intervention }: { intervention: Intervention }) => {
          return (
            !!intervention.estimate_sellsy_id || !!intervention.product_estimate_sellsy_id || !!intervention.estimate_sinao_id || !!intervention.fk_estimate_id || !!intervention.fk_quote_id
          );
        },
        'panel:edit_service': ({ service }) => {
          return service && !['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
        },
        'panel:dispute': ({ intervention }: { intervention: Intervention }) => {
          return intervention.slots.every(slot => !slot.fk_mk_invoice_id) && !intervention.dispute_status;
        },
        'timeline:close_dispute': ({ intervention }) => {
          return intervention && intervention.dispute_status === 'started';
        },
      },
    },
    user: {
      static: [
        'dashboard:create_service',
        'header:trial',
        'intervention:user_cta',
        'timeline:client_price',
        'organisation:create_user',
        'panel:user',
        'dashboard:tags_type',
        'check_list:ko_list',
      ],
      dynamic: {
        'timeline:edit_contact': ({ intervention }) => {
          return intervention;
        },
        'timeline:change_address': ({ intervention }) => {
          return intervention && !['ongoing', 'started', 'ended'].includes(intervention.status);
        },
        'timeline:view_equipments': ({ service }) => {
          return service && service.equipment_status && !['client-request', 'pending-validation', 'pending-renewal'].includes(service.equipment_status);
        },
        'timeline:edit_service': ({ service }) => {
          return service && ['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
        },
        'report:sign': ({ is_signed }) => {
          return !is_signed;
        },
        'drafts:transform': ({ addresses }) => {
          return addresses && addresses.length > 0;
        },
        'panel:invoice': ({ service }) => {
          return service.invoice_sellsy_id && service.invoice_sellsy_id !== -1 && service.invoice_status !== 'draft';
        },
        'panel:cancel_intervention': ({ intervention }: { intervention: Intervention }) => {
          return (
            intervention.status === 'expired' ||
            (['pending', 'planned'].includes(intervention.status) &&
              !intervention.estimate_sellsy_id &&
              !intervention.product_estimate_sellsy_id &&
              !intervention.estimate_sinao_id)
          );
        },
        'panel:renew_estimate': ({ intervention }: { intervention: Intervention }) => {
          return (
            intervention.status === 'expired' &&
            (intervention.estimate_sellsy_id !== null || intervention.product_estimate_sellsy_id !== null || intervention.estimate_sinao_id !== null)
          );
        },
        'panel:check_estimate': ({ intervention }: { intervention: Intervention }) => {
          return (
            !!intervention.estimate_sellsy_id || !!intervention.product_estimate_sellsy_id || !!intervention.estimate_sinao_id || !!intervention.fk_estimate_id
          );
        },
        'panel:edit_service': ({ service }) => {
          return service && !['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
        },
        'panel:dispute': ({ intervention }: { intervention: Intervention }) => {
          return intervention.slots.every(slot => !slot.fk_mk_invoice_id) && !intervention.dispute_status;
        },
        'timeline:close_dispute': ({ intervention }) => {
          return intervention && intervention.dispute_status === 'started';
        },
      },
    },
    restricted: {
      static: ['header:trial', 'timeline:client_price', 'panel:user', 'dashboard:tags_type', 'check_list:ko_list'],
      dynamic: {
        'timeline:change_address': ({ intervention }) => {
          return intervention && !['ongoing', 'started', 'ended'].includes(intervention.status);
        },
        'timeline:view_equipments': ({ service }) => {
          return service && service.product_estimate_sellsy_id;
        },
        'timeline:edit_service': ({ service }) => {
          return service && ['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
        },
        'report:sign': ({ is_signed }) => {
          return !is_signed;
        },
        'panel:check_estimate': ({ intervention }: { intervention: Intervention }) => {
          return (
            !!intervention.estimate_sellsy_id || !!intervention.product_estimate_sellsy_id || !!intervention.estimate_sinao_id || !!intervention.fk_estimate_id
          );
        },
        'panel:edit_service': ({ service }) => {
          return service && ['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
        },
      },
    },
  },
  jack: {
    admin: {
      static: [...jack_rules.static!],
      dynamic: {
        ...jack_rules.dynamic,
        'timeline:assign_jack': ({ intervention }) => {
          return intervention && !intervention.jack && ['pending', 'planned'].includes(intervention.status);
        },
        'timeline:change_jack': ({ intervention }) => {
          return intervention && intervention.jack && ['pending', 'planned'].includes(intervention.status);
        },
        'timeline:link_estimates': ({ intervention }) => false,
        // 'timeline:link_estimates': ({ intervention }) => {
        //   return (
        //     intervention &&
        //     !intervention.jack?.JackInfo.invoice_handled &&
        //     ['pending', 'planned'].includes(intervention.status) &&
        //     intervention.estimate_status !== 'accepted'
        //   );
        // },
      },
    },
    user: {
      static: jack_rules.static,
      dynamic: {
        ...jack_rules.dynamic!,
        'timeline:link_estimates': ({ intervention }) => false
        // 'timeline:link_estimates': ({ intervention }) => {
        //   return (
        //     intervention &&
        //     !intervention.jack?.JackInfo.invoice_handled &&
        //     ['pending', 'planned'].includes(intervention.status) &&
        //     intervention.estimate_status !== 'accepted'
        //   );
        // },
      },
    },
    restricted: jack_rules,
  },
};

export default rules;
