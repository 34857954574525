import * as React from 'react';
import { Translate } from 'react-localize-redux';

import Modal from 'components/widgets/modal';
import jack from 'components/routes/drafts/jack-empty-task.png';

import styles from './error-modal.module.css';

type ErrorModalProps = {
  title: string,
  message: string,
  infos?: React.ReactNode,
  action?: React.ReactNode,
}

const ErrorModal = ({ title, message, infos, action }: ErrorModalProps) => (
  <Modal title={title} onClose={() => { }}>
    <span className={styles['error-message']}>
      <Translate id={message} />
    </span>
    <p className={styles['infos']}>{infos}</p>
    <div className={styles['logo-wrapper']}>
      <img className={styles['logo']} src={jack} alt='jack' />
    </div>
    <br />
    {action}
  </Modal>
);

export default ErrorModal;
