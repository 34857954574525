import * as React from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

import { useOfflineStore } from 'components/hooks/stores/offline';
import { MJColors } from 'types/colors';

import styles from './button.module.css';

export type MJButton = {
  align?: 'left' | 'center' | 'right' | 'full',
  type?: 'button' | 'submit',
  color?: MJColors,
  size?: 'big' | 'medium' | 'small' | 'long' | 'slim' | 'longer' | 'smaller',
  variant?: 'plain' | 'reversed' | 'link',
  disabled?: boolean,
  shape?: 'normal' | 'round' | 'cubic' | 'icon'
  cypress_id?: string,
  text?: React.ReactNode,
  icon?: IconDefinition,
  with_pending_network?: boolean,
  with_offline_mode?: boolean,
  with_fetching_network?: boolean,
  nowrap?: boolean,
  tracking?: {
    tracking_id: string,
    metadata: { [key: string]: any }
  },
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button = ({
  align = 'left', type = 'button', color = 'secondary', cypress_id, variant = 'plain',
  size = 'big', disabled = false, shape = 'normal', text, icon, with_pending_network = false,
  with_fetching_network = false, with_offline_mode = false, tracking, onClick, nowrap = false,
}: MJButton) => {
  const is_mutating = useIsMutating();
  const is_fetching = useIsFetching();
  const syncing = useOfflineStore(store => store.syncing);
  const is_syncing = with_offline_mode && syncing;
  const is_networked = (with_pending_network && is_mutating > 0) || (with_fetching_network && is_fetching > 0);
  const is_disabled = disabled || is_networked || is_syncing;

  return <div className={classNames({
    [styles['align-left']]: align === 'left',
    [styles['align-center']]: align === 'center',
    [styles['align-right']]: align === 'right',
    [styles['align-full']]: align === 'full',
  })}>
    <button
      data-cy={cypress_id}
      disabled={is_disabled}
      type={type}
      className={classNames({
        [styles['btn']]: true,
        [styles[`btn-${shape}-${size}`]]: true,
        [styles['btn-full']]: align === 'full',
        [styles[`btn-${color}-${variant}`]]: !is_networked,
        [styles[`btn-disabled-${variant}`]]: is_disabled,
        [styles[`nowrap`]]: nowrap

      })}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
      }}>
        {(is_networked || is_syncing)
          &&
          <FontAwesomeIcon className={styles[text ? 'icon' : '']} icon={faSpinnerThird} spin />
        }
        {(!is_networked && !is_syncing) && icon
          &&
          <FontAwesomeIcon className={styles[text ? 'icon' : '']} icon={icon} />
        }
        { is_syncing && text ? 'Synchronisation' : text}
      </button>
  </div>
};

export default Button;
