import create from 'zustand';

import { RocketChatStore } from 'types/store';
import { RocketLoginUser } from 'types/rocket-chat';

export const useRocketChatStore = create<RocketChatStore>(set => ({
  rc_user: undefined,
  is_logged_to_rocket_chat: false,
  has_notifications: false,
  login: (rc_user: RocketLoginUser) => set({ rc_user, is_logged_to_rocket_chat: true }),
  logout: () => set({ rc_user: undefined, is_logged_to_rocket_chat: false }),
  setNotifications: (has_notifications: boolean) => set({ has_notifications })
}));
