import axios, { AxiosError, ResponseType } from 'axios';
import bowser from 'bowser';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Capacitor } from '@capacitor/core';

import AppConfig from 'config';
import { useUserStore } from 'components/hooks/stores/user';
import { useOrganisationStore } from 'components/hooks/stores/organisation';
import { useAppStore } from 'components/hooks/stores/app';
import { useJackStore } from 'components/hooks/stores/jack';
import { User } from 'types/user';
import { useOfflineStore } from 'components/hooks/stores/offline';

import packageInfo from '../../package.json';

type OfflineConfig = {
  refresh_key?: (string | number)[];
  priority?: number;
};

export type RequestOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
  error_message?: string;
  body?: object;
  params?: object;
  headers?: HeadersInit;
  file?: boolean;
  user?: User; // TODO: remove me
  raw_response?: boolean;
  baseURL?: string;
  onUploadProgress?: (event: any) => void;
  authenticated?: boolean;
  api_version?: string | number;
  offline?: OfflineConfig;
};

export async function forgeRefreshRequest() {
  const refresh_token = useUserStore.getState().refresh_token;
  const user_id = useUserStore.getState().id;
  const phone_number = useUserStore.getState().phone_number;
  const browser = bowser.getParser(window.navigator.userAgent);
  const browser_info = browser.getBrowser();
  const platform = browser.getPlatform();
  let device_id = useAppStore.getState().device_id;

  if (!device_id) {
    const fingerprint = await FingerprintJS.load();
    const signature = await fingerprint.get();

    device_id = signature.visitorId;
    useAppStore.getState().setDeviceId(device_id);
  }

  return axios.request({
    method: 'POST',
    baseURL: `${AppConfig.api.protocol}://${AppConfig.api.url}/v1`,
    url: '/users/login/refresh',
    data: {
      refresh_token,
      device: {
        device_id,
        device_version: `${browser_info.name} ${platform.type} ${browser_info.version} - v${packageInfo.version}`,
        device_type: Capacitor.getPlatform(),
      },
    },
    auth: {
      username: user_id.toString(),
      password: phone_number,
    },
    timeout: AppConfig.offline.sync_timeout,
  });
}

async function renewAccessToken(options: RequestOptions) {
  try {
    const { data } = await forgeRefreshRequest();

    useUserStore.getState().refreshCredentials(data.credentials.access_token, data.credentials.refresh_token);
    // Replaying original request
    const replay_headers = {
      ...options.headers,
      Authorization: `Bearer ${data.credentials.access_token}`,
    };

    options.headers = replay_headers;
    return request(options);
  } catch (err) {
    useUserStore.getState().reset();
    useOrganisationStore.getState().reset();
    useJackStore.getState().reset();
    useAppStore.getState().logOut();

    // TODO: Fixme
    // return push('/login');
  }
}

export function generateAxiosRequestConfig(options: RequestOptions) {
  let default_headers: HeadersInit = {};

  if (options.authenticated !== false) {
    const access_token = useUserStore.getState().access_token;

    default_headers['Authorization'] = `Bearer ${access_token}`;
  }

  return {
    baseURL: options.baseURL || `${AppConfig.api.protocol}://${AppConfig.api.url}/v${options.api_version || '1'}`,
    url: options.path,
    method: options.method || 'GET',
    headers: {
      ...default_headers,
      ...options.headers,
    },
    data: options.body,
    params: options.params,
    responseType: (options.file ? 'blob' : 'json') as ResponseType,
    onUploadProgress: options.onUploadProgress,
  };
}

export async function request(options: RequestOptions): Promise<any> {
  const online = useAppStore.getState().online;

  // Handling manual offline mode
  if (options.offline && !online) {
    const error = new Error() as AxiosError;

    useOfflineStore
      .getState()
      .addRequest(generateAxiosRequestConfig(options), options.offline.priority || AppConfig.offline.default_priority, options.offline.refresh_key);
    error.code = 'network_offline';
    throw error;
  }

  try {
    const request_config = generateAxiosRequestConfig(options);
    const response = await axios.request(request_config);

    if (options.raw_response) {
      return response;
    }
    // It means we receive a logical error and it must be treated as an error then in the following logic
    if (response.data && response.data.code) {
      throw response.data;
    }
    return response.data;
  } catch (except) {
    const err = except as Error | AxiosError;

    if (axios.isAxiosError(err)) {
      // Means we are offline
      if (!err.response) {
        err.code = 'network_offline';
        throw err;
      }
      const error_code = err.response.status;
      const error = err.response.data as AxiosError;

      // Refresh access token
      if (error_code === 401 && error.code === 'E_TOKEN_EXPIRED') {
        return renewAccessToken(options);
      }

      if (error_code && error_code >= 400) {
        const code = error?.code;

        if (error_code === 413) {
          err.response.data = { developerMessage: 'errors.entity_too_large', code };
        }
        if (error_code === 403) {
          err.response.data = { developerMessage: 'errors.forbidden', code };
        }

        if (error_code >= 500) {
          if (error.code) {
            err.response.data = error;
          } else {
            err.response.data = { developerMessage: 'errors.server_error' };
          }
        }
        throw err.response.data;
      }
    }
    err.message = 'errors.server_error';
    throw err;
  }
}
