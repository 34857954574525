import create from 'zustand';
import { persist } from 'zustand/middleware'

import { Availability } from 'types/jack';

const default_availability: Availability = {
  id: 0,
  week: 0,
  pristine_week: false,
  pristine_weekend: false,
};

type AvailabilityStore = Availability & {
  setAvailability: (availability: Availability) => void,
  reset: () => void
}

export const useAvailabilityStore = create<AvailabilityStore>()(persist(set => ({
  ...default_availability,
  reset: () => set({ ...default_availability }),
  setAvailability: (availability) => set(state => ({ ...state, ...availability })),
}), { name: 'availability-store' }));
