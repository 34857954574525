import {
  IconDefinition,
  faChartPie,
  faCalendarAlt,
  faCommentsAlt,
  faHistory,
  faTools,
  faUsers,
  faUserHardHat,
  faUserCog,
  faSearchDollar,
  faListUl,
  faClipboardListCheck,
  faCircleQuestion,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons';

import { Organisation } from 'types/organisation';
import { User } from 'types/user';

export type Entry = {
  key: number;
  id?: string;
  cypress_id: string;
  name: string;
  path?: string;
  external?: boolean;
  icon: IconDefinition;
  has_notifications?: boolean;
  onClick?: () => void;
};

export function generateMenu(user: Pick<User, 'type' | 'role'>, organisation: Organisation, tabbar = false) {
  const dashboard = {
    key: 1,
    cypress_id: 'header-dashboard',
    name: 'header.dashboard',
    path: 'dashboard',
    icon: faListUl,
  };
  const drafts = {
    key: 2,
    cypress_id: 'header-drafts',
    name: 'header.drafts',
    path: 'drafts',
    icon: faClipboardListCheck,
  };
  const history = {
    key: 3,
    cypress_id: 'header-history',
    name: 'header.history',
    path: 'history',
    icon: faHistory,
  };
  const planning = {
    key: 4,
    cypress_id: 'header-planning',
    name: 'header.planning',
    path: 'planning',
    icon: faCalendarAlt,
  };
  const equipments = {
    key: 5,
    cypress_id: 'header-equipments',
    name: 'header.equipments',
    path: 'equipments',
    icon: faTools,
  };
  const inbox = {
    key: 6,
    cypress_id: 'header-inbox',
    name: 'header.inbox',
    path: 'inbox',
    icon: faCommentsAlt,
  };
  const organisations = {
    key: 7,
    cypress_id: 'header-organisations',
    name: 'header.organisations',
    path: 'organisations',
    icon: faUsers,
  };
  const client_organisation = {
    key: 8,
    cypress_id: 'header-client-organisation',
    name: 'header.client_organisation',
    path: `organisations/${organisation.id}`,
    icon: faChartPie,
  };
  const jacks = {
    key: 9,
    cypress_id: 'header-jacks',
    name: 'header.jacks',
    path: 'jacks',
    icon: faUserHardHat,
  };
  const profile = {
    key: 10,
    cypress_id: 'header-profile',
    name: 'header.profile',
    path: 'profile',
    icon: faUserCog,
  };
  const marketplace = {
    key: 11,
    cypress_id: 'header-marketplace',
    name: 'header.marketplace',
    path: 'marketplace',
    icon: faSearchDollar,
  };
  const help = {
    key: 12,
    cypress_id: 'header-help',
    name: 'header.help',
    path: 'https://help.mercijack.co',
    icon: faCircleQuestion,
    external: true,
  };
  const order = {
    key: 13,
    cypress_id: 'header-order',
    name: 'header.order',
    path: 'order',
    icon: faScrewdriverWrench,
  };

  if (tabbar) {
    switch (user.type) {
      case 'jack':
        if (user.role === 'restricted') {
          return [dashboard, inbox, history] as Entry[];
        }
        return [dashboard, marketplace, inbox, history, profile] as Entry[];
      case 'ops':
        return [dashboard, history, inbox] as Entry[];
      default:
        return [dashboard, inbox, client_organisation] as Entry[];
    }
  }
  let menu_entries: Entry[] = [dashboard, order, drafts, inbox, history, client_organisation, profile, help];

  if (user.type === 'jack') {
    menu_entries = [dashboard, marketplace, planning, inbox, history, profile];

    if (user.role === 'admin') {
      menu_entries.push(jacks);
    }

    if (user.role === 'restricted') {
      menu_entries.splice(1, 1);
    }
  } else if (user.type === 'ops') {
    menu_entries = [dashboard, order, planning, inbox, equipments, history, organisations, jacks, profile];
  }

  // Restricted users have no access to "my company" page too
  if (user.role === 'restricted') {
    menu_entries.splice(1, 1);
    menu_entries.splice(4, 1);
  }

  if (user.role === 'manager') {
    menu_entries = [dashboard, order, drafts, planning, inbox, history, client_organisation, jacks, profile];
  }

  return menu_entries;
}
