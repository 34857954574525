import * as React from 'react';
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';

import { useAppStore } from 'components/hooks/stores/app';
import { logoutUserAndCleanAllStores } from 'api/users';

import Sidenav from './nav/sidenav';
import Tabbar from './nav/tabbar';

export type SidenavState = {
  expand: boolean,
  mobile_visible: boolean
};

function reducer(state: SidenavState, { type }: any) {
  switch (type) {
    case 'TOGGLE_SIDENAV':
      return {
        ...state,
        expand: !state.expand
      };
    case 'DISPLAY_MOBILE':
      return {
        ...state,
        expand: true,
        mobile_visible: true
      };
    case 'HIDE_MOBILE':
      return {
        ...state,
        mobile_visible: false
      };
    default:
      throw new Error();
  }
}

const Navigation = () => {
  const navigate = useNavigate();
  const is_logged_in = useAppStore(app => app.is_logged_in);
  const initial_state = localStorage.getItem('sidenav');
  const [state, dispatch] = React.useReducer(reducer, {
    expand: !initial_state ? true : JSON.parse(initial_state),
    mobile_visible: false
  });

  React.useEffect(() => {
    localStorage.setItem('sidenav', state.expand.toString());
  }, [state.expand]);

  if (!is_logged_in) {
    return <React.Fragment></React.Fragment>;
  }

  function logout() {
    logoutUserAndCleanAllStores();
    navigate('/login');
  }

  return <React.Fragment>
    <Sidenav
      disconnect={logout}
      state={state}
      dispatch={dispatch} />
    <Tabbar
      state={state}
      dispatch={dispatch} />
  </React.Fragment>;
};

export default Navigation;
