import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faTimes, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import AltPanelSection, { AltSection } from './alt-panel-section'
import styles from './alt-panel.module.css';

export type AltPanelProps = {
  title: React.ReactNode,
  title_icon?: IconDefinition,
  sections: AltSection[],
  closePanel?: () => void
}

const AltPanel = ({ title, title_icon, sections, closePanel }: AltPanelProps) => (
  <div className={styles['panel']}>
    <div className={styles['panel-header']}>
      <FontAwesomeIcon icon={title_icon || faInfoCircle} className={styles['panel-title-icon']} />
      <h1 className={styles['panel-title']}>{title}</h1>
      {closePanel && <FontAwesomeIcon icon={faTimes} className={styles['close-icon']} onClick={closePanel} />}
    </div>
    <ul className={styles['panel-body']}>
      {sections.map((section, idx) => <AltPanelSection
        key={section.id}
        section={section} />)}
    </ul>
  </div>
);

export default AltPanel;
