import * as React from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getMessaging, Messaging, getToken, onMessage } from 'firebase/messaging';
import isEmpty from 'lodash/isEmpty';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import shallow from 'zustand/shallow';
import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Dialog } from '@capacitor/dialog';
import { useNavigate } from 'react-router-dom';

import AppConfig from 'config';
import { User } from 'types/user';
import { useAppStore, usePushStore } from 'components/hooks/stores/app';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

export default function useFirebase(is_logged_in: boolean, user: User = {} as User, registerDevice: (user_id: number, push_id: string, device_id: string) => void) {
  const navigate = useNavigate();
  const messaging = React.useRef<Messaging>();
  const { push_status, setPushStatus } = usePushStore(store => ({
    push_status : store.push_status,
    setPushStatus: store.setPushStatus,
  }), shallow);
  const { registration, device_id, setDeviceId } = useAppStore(app => ({
    registration: app.registration,
    device_id: app.device_id,
    setDeviceId: app.setDeviceId
  }), shallow);

  const pushRegistration = React.useCallback(async (token: string) => {
    let user_device_id = device_id;

    if (!user_device_id) {
      const fingerprint = await FingerprintJS.load();
      const signature = await fingerprint.get();

      user_device_id = signature.visitorId;
      setDeviceId(user_device_id);
    }

    setPushStatus('enabled');
    return registerDevice(user.id, token, user_device_id);
  }, [registerDevice, setPushStatus, user.id, device_id, setDeviceId]);

  const onPushClick = React.useCallback((notification: PushNotificationSchema) => {
    const prefix = user.type === 'pro' ? '' : `/${user.type}`
    const intervention_id = notification.data?.id;
    const entity_type = notification.data?.entity_type;
    const intervention_type = notification.data?.type;

    if (intervention_id && entity_type === 'intervention') {
      navigate(`${prefix}/${['curative', 'corrective', 'diagnosis'].includes(intervention_type) ? 'timeline' : 'check-report'}/${intervention_id}`);
    }
  }, [navigate, user.type]);

  const showNotification = React.useCallback(async (notification: PushNotificationSchema) => {
    const { value } = await Dialog.confirm({
      title: notification.title || 'Merci Jack',
      message: notification.body || '',
      okButtonTitle: 'Voir',
      cancelButtonTitle: 'Fermer'
    });

    if (value) {
      onPushClick(notification);
    }
  }, [onPushClick]);

  const androidPushRegistration = React.useCallback(async () => {
    await PushNotifications.register();
    const res = await PushNotifications.listChannels();

    if (res && !res.channels.find(channel => channel.id === 'general')) {
      await PushNotifications.createChannel({
        id: 'general',
        name: 'General',
        importance: 4,
        visibility: 1,
        description: "Ensemble des notifications relatives à Merci Jack",
        lights: true,
        vibration: true,
      });
    }

    await PushNotifications.addListener('registration', (token: Token) => pushRegistration(token.value));

    await PushNotifications.addListener('registrationError', () => setPushStatus('disabled'));

    await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => showNotification(notification));

    await PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => onPushClick(action.notification));
  }, [pushRegistration, setPushStatus, showNotification, onPushClick]);



  React.useEffect(() => {
    const platform = Capacitor.getPlatform();

    async function checkAndRegister() {
      const permission = await PushNotifications.checkPermissions();

      if (permission.receive === 'granted') {
        return androidPushRegistration();
      }
      return setPushStatus('disabled');
    }

    if (platform === 'android') {
      checkAndRegister();
    }
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    const platform = Capacitor.getPlatform();

    if (platform === 'web' && is_logged_in && getApps().length === 0) {
      try {
        const app = initializeApp(AppConfig.firebase.global);

        messaging.current = getMessaging(app);

        onMessage(messaging.current, payload => {
          if (window.Notification) {
            return new Notification(payload.notification?.title || 'Merci Jack', {
              ...payload.notification,
              vibrate: 200
            });
          }
          return registration?.showNotification(payload.notification?.title || 'Merci Jack', {
            ...payload.notification,
            icon: payload.notification?.image
          });
        });
      }
      catch (err) {
        console.log(err);
        setPushStatus('unsupported');
      }
    }
    /* eslint-disable-next-line */
  }, [is_logged_in]);

  React.useEffect(() => {
    fetchAndActivate(getRemoteConfig(getApps()[0]));
  }, [registration, is_logged_in, user]);

  React.useEffect(() => {
    const platform = Capacitor.getPlatform();

    async function setupCloudMessaging() {
      try {
        // Can happen we login / logout
        if (!messaging.current && getApps().length > 0) {
          messaging.current = getMessaging(getApps()[0]);
        }
        const token = await getToken(messaging.current!, { vapidKey: AppConfig.firebase.push.vapidKey, serviceWorkerRegistration: registration });

        // Need user's permission first
        if (!token) {
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            const new_token = await getToken(messaging.current!, { vapidKey: AppConfig.firebase.push.vapidKey, serviceWorkerRegistration: registration });

            return pushRegistration(new_token);
          }
          return setPushStatus('disabled');
        }
        return pushRegistration(token);
      }
      catch (err) {
        console.error(err);
        return setPushStatus('disabled');
      }
    }

    if (platform === 'web' && push_status !== 'enabled' && is_logged_in && !isEmpty(user) && registration && registration instanceof ServiceWorkerRegistration) {
      setupCloudMessaging();
    }
    /* eslint-disable-next-line */
  }, [push_status, registration, is_logged_in, user, registerDevice]);
}
