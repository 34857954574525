import { activity_areas, sub_activity_areas } from 'types/config';

import { User } from './user';
import { Address } from './address';
import { ExtendedFile } from './upload';

export type OrganisationBillingType = 'per-task' | 'monthly';
export type OrganisationSubscriptionType = 'regular' | 'premium' | 'all-inclusive';
export type OrganisationVerificationStatus = 'verified' | 'unverified' | 'pending';
export type OrganisationTermsStatus = 'accepted' | 'acceptation-needed' | 'never-accepted' | 'refused';
export type InvoiceStats = { total_due: number; total_late: number; price_due: number; price_late: number };

export const edit_organisation_keys = [
  'name',
  'real_name',
  'logo',
  'billing_name',
  'billing_lastname',
  'billing_firstname',
  'billing_ref',
  'siret_number',
  'tva_number',
  'billing_emails',
  'billing_address',
  'billing_postcode',
  'billing_city',
  'activity_area',
  'sub_activity_area',
  'unlock_order',
];

export type Organisation = {
  id: number;
  siret_number: string;
  can_order: boolean;
  client_sellsy_id: number;
  real_name: string;
  billing_name: string;
  billing_ref?: string;
  billing_address: string;
  billing_city: string;
  billing_postcode: string;
  billing_emails: string[];
  logo_url: string;
  name: string;
  type: 'client' | 'jack';
  tva_number: string;
  public_key: string;
  next_invoice?: string;
  last_invoice?: string;
  trial_start?: Date;
  subscription_type: OrganisationSubscriptionType;
  children: Organisation[];
  Children: Organisation[]; //a retirer asap (getOrganisations retourne des Children)
  OrganisationUsers: User[];
  account?: User;
  sales?: User;
  Addresses: Address[];
  siblings: Organisation[];
  parent?: Organisation;
  verification_status: OrganisationVerificationStatus;
  terms_status: OrganisationTermsStatus;
  terms_accepted_at?: Date;
  algolia_key: string;
  hourly_price: number;
  hourly_price_2: number;
  hourly_price_3: number;
  travel_price: number;
  travel_price_2: number;
  travel_price_3: number;
  billing_type: OrganisationBillingType;
  activity_area: typeof activity_areas[number];
  sub_activity_area?:
    | typeof sub_activity_areas['bar-restaurant'][number]
    | typeof sub_activity_areas['education'][number]
    | typeof sub_activity_areas['office'][number]
    | typeof sub_activity_areas['retail'][number]
    | typeof sub_activity_areas['sport'][number];
  unlock_order?: boolean;
  client_sinao_id?: number;
  invoice_stats: {
    total_due: number;
    total_late: number;
    price_due: number;
    price_late: number;
  };
  has_valid_payment_method: boolean;
  has_to_accept_terms: boolean;
};

export type OrganisationFields = Pick<
  Organisation,
  | 'name'
  | 'real_name'
  | 'siret_number'
  | 'tva_number'
  | 'billing_emails'
  | 'billing_ref'
  | 'billing_name'
  | 'billing_address'
  | 'billing_postcode'
  | 'billing_city'
  | 'activity_area'
  | 'sub_activity_area'
  | 'unlock_order'
> & {
  logo: ExtendedFile[] | string[];
  organisation_type: 'draft' | 'signed';
  prospect?: boolean;
};

export type OrganisationRegisterFields = Pick<
  Organisation,
  | 'name'
  | 'siret_number'
  | 'tva_number'
  | 'billing_address'
  | 'billing_postcode'
  | 'billing_city'
  | 'activity_area'
  > & Pick<User, 'first_name' | 'last_name' | 'phone_number' | 'email' | 'password'>
  & {
    billing_first_name: string;
    billing_last_name: string;
    billing_email: string;
    terms_accepted: boolean;
  };

export type JackOrganisationFields = Pick<
  Organisation,
  | 'name'
  | 'real_name'
  | 'hourly_price'
  | 'travel_price'
  | 'hourly_price_2'
  | 'travel_price_2'
  | 'hourly_price_3'
  | 'travel_price_3'
  | 'billing_postcode'
  | 'billing_address'
  | 'billing_city'
> & {
  area_id: string;
  logo: ExtendedFile[];
};

export const SubscriptionTypeColor: any = {
  regular: 'grey',
  premium: 'success',
  'all-inclusive': 'secondary',
  draft: 'extra',
};
