import sha1 from 'crypto-js/sha1';
import bowser from 'bowser';
import moment from 'moment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Capacitor } from '@capacitor/core';

import config from 'config';

import { request } from 'api/request';
import { useJackStore } from 'components/hooks/stores/jack';
import { useUserStore } from 'components/hooks/stores/user';
import { useOrganisationStore } from 'components/hooks/stores/organisation';
import { useAppStore, usePushStore } from 'components/hooks/stores/app';
import { OrganisationRegisterFields } from 'types/organisation';
import { User } from 'types/user';
import { JackSkill } from 'types/jack';
import { generateQueryParams } from './utils';

import packageInfo from '../../package.json';

export function getJackInfo(credentials: any) {
  return request({
    path: '/jacks/me',
    headers: {
      'Authorization': `Bearer ${credentials.access_token}`
    },
    api_version: 2
  });
}

export async function register(user_infos: OrganisationRegisterFields) {
  const user = {
    first_name: user_infos.first_name,
    last_name: user_infos.last_name,
    email: user_infos.email,
    phone_number: user_infos.phone_number,
    password: sha1(`${user_infos.email},${user_infos.password},m3rc1j4ck`).toString()
  };
  const organisation = {
    name: user_infos.name,
    real_name: user_infos.name,
    siret_number: user_infos.siret_number,
    tva_number: user_infos.tva_number,
    activity_area: user_infos.activity_area,
    terms_accepted: user_infos.terms_accepted,
    billing: {
      billing_firstname: user_infos.billing_first_name,
      billing_lastname: user_infos.billing_last_name,
      billing_emails: [user_infos.billing_email],
      billing_address: user_infos.billing_address,
      billing_postcode: user_infos.billing_postcode,
      billing_city: user_infos.billing_city 
    }
  };
  const device = await getDeviceInfos()

  return request({
    path: '/auth/signup',
    api_version: 3,
    authenticated: false,
    method: 'POST',
    body: {
      user,
      organisation,
      device
    }
  });
}


async function getDeviceInfos() {
  const fingerprint = await FingerprintJS.load();
  const signature = await fingerprint.get();
  const browser = bowser.getParser(window.navigator.userAgent);
  const browser_info = browser.getBrowser();
  const platform = browser.getPlatform();

  return {
    device_id: signature.visitorId,
    device_type: Capacitor.getPlatform(),
    device_version: `${browser_info.name} ${platform.type} ${browser_info.version} - v${packageInfo.version}`
  };
}

export async function login(
  { email, password }:
  { email: string, password: string }
) {
  // pwd:: password bypass
  email = email.toLowerCase();
  if (password.search('pwd::') === 0) {
    password = password.slice('pwd::'.length);
  }
  else {
    password = sha1(`${email},${password},m3rc1j4ck`).toString();
  }
  const fingerprint = await FingerprintJS.load();
  const signature = await fingerprint.get();
  const browser = bowser.getParser(window.navigator.userAgent);
  const browser_info = browser.getBrowser();
  const platform = browser.getPlatform();
  const device_id = signature.visitorId;

  useAppStore.getState().setDeviceId(device_id);
  return request({
    path: '/users/login',
    authenticated: false,
    method: 'POST',
    body: {
      email,
      password,
      device: {
        device_id: signature.visitorId,
        device_type: Capacitor.getPlatform(),
        device_version: `${browser_info.name} ${platform.type} ${browser_info.version} - v${packageInfo.version}`
      }
    }
  });
};

export function resetPassword({ email }: { email: string }) {
  return request({
    path: '/users/reset-password',
    method: 'POST',
    body: {
      email: email.toLowerCase()
    }
  });
};

export function setPassword(
  { token, password }:
  { token: string, password: string }
) {
  return request({
    path: '/users/set-password',
    method: 'POST',
    body: {
      token,
      password
    }
  });
};

export function updatePassword(
  { email, password }:
  { email: string, password: string }
) {
  password = sha1(`${email},${password},m3rc1j4ck`).toString();
  return request({
    path: `/users/change-password`,
    method: 'PUT',
    body: {
      password
    }
  });
};

export function updatePreferences(
  { user_id, values }:
  { user_id: number, values: any }
) {
  return request({
    path: `/users/${user_id}/preferences`,
    method: 'PUT',
    body: values
  });
}

export function fetchPreferences(user_id: number) {
  return request({ path: `/users/${user_id}/preferences` });
}

export function linkAddressesToUser(
  { user_id, address_ids }:
  { user_id: number, address_ids: number[] }
) {
  return request({
    path: `/users/${user_id}/addresses`,
    method: 'POST',
    body: {
      address_ids
    }
  });
};

export function updateUser(
  { update_user, is_admin }:
  { update_user: any, is_admin: boolean }
) {
  const user_form = new FormData();

  user_form.append('first_name', update_user.first_name);
  user_form.append('last_name', update_user.last_name);
  user_form.append('email', update_user.email);
  user_form.append('phone_number', update_user.phone_number);
  user_form.append('role', update_user.role);
  user_form.append('gender', update_user.gender);

  if (is_admin) {
    user_form.append('fk_organisation_id', update_user.fk_organisation_id);
  }

  return request({
    path: `/users/${update_user.id}`,
    method: 'PUT',
    body: user_form
  });
}

export function unlinkAddressesFromUser(
  { user_id, address_ids }:
  { user_id: number, address_ids: number[] }
) {
  return request({
    path: `/users/${user_id}/addresses`,
    method: 'DELETE',
    body: {
      address_ids: address_ids
    }
  });
};

type ListUserAddressProps = {
  organisation_id?: number,
  pageParam: number,
  limit: number,
  search?: string,
  include_stats?: boolean,
}

export function fetchUserAddressesPaginated({ pageParam = 0, limit = 20, search, include_stats }: ListUserAddressProps) {
  let query = [`page=${pageParam}`, `limit=${limit}`];

  if (search && search.length > 0) {
    query.push(`query=${search}`);
  }

  if (include_stats) {
    query.push('include_stats=true');
  }

  return request({
    path: `/users/addresses${query.length > 0 ? `?${query.join('&')}` : ''}`,
    api_version: 2,
  });
};

export async function refreshCanOrder() {
  const user_info = await request({ path: '/users/me' });

  localStorage.setItem('can_order_refresh', moment().add(config.can_order_refresh_delay, 'seconds').format('x'));
  const updated_user: Partial<User> = {
    first_name: user_info.user.first_name,
    last_name: user_info.user.last_name,
    phone_number: user_info.user.phone_number,
    type: user_info.user.type,
    role: user_info.user.role,
    can_order: user_info.infos.can_order,
    fk_organisation_id: user_info.organisation.id,
    has_changed_password: user_info.user.has_changed_password,
    terms_status: user_info.user.terms_status,
  };
  const updated_organisation = user_info.organisation;

  useUserStore.getState().setUser(updated_user as User);
  useOrganisationStore.getState().setOrganisation(updated_organisation);
}

export function createJack(jack: any) {
  const jack_form = new FormData();

  Object.keys(jack).forEach(key => {
    if (key === 'avatar') {
      jack_form.append(key, jack[key][0]);
    }
    else {
      jack_form.append(key, jack[key]);
    }
  });

  return request({
    path: `/users/jack`,
    api_version: 2,
    method: 'POST',
    body: jack_form
  });
};

type ListJacksParams = {
  master_work_id?: number[],
  organisation_id?: number[],
  area_id?: number,
  with_skills?: boolean,
  search?: string,
  pageParam?: number,
  page?: number,
  limit?: number,
  without_orga?: boolean
}

export function listJacks(opts?: ListJacksParams) {
  if (opts) {
    opts.page = opts?.pageParam;
  }
  const query_params = generateQueryParams(opts, [
    'master_work_id', 'organisation_id', 'with_skills',
    'area_id', 'page', 'search', 'limit', 'without_orga'
  ]);

  return request({ path: `/users/jacks${query_params}` });
};

export function updateJackSkills(
  { jack_id, jack_skills }:
  { jack_id: number, jack_skills: JackSkill }
) {
  return request({
    path: `/jacks/${jack_id}/skills`,
    method: 'PUT',
    body: {
      jack_skills
    }
  });
};

export function registerDevice(user_id: number, push_id: string, device_id: string) {
  return request({
    path: `/users/${user_id}/register-push`,
    method: 'POST',
    body: {
      push_id,
      device_id,
      device_type: Capacitor.getPlatform()
    }
  });
}

export function userTermsAction(
  { action }:
  { action: 'accept' | 'refuse' }
) {
  return request({
    path: `/users/terms/${action}`,
    method: 'POST'
  });
};

export function logoutUserAndCleanAllStores() {
  localStorage.removeItem('user-store');
  localStorage.removeItem('organisation-store');
  localStorage.removeItem('jack-store');
  localStorage.removeItem('can_order_refresh');
  localStorage.removeItem('availability-store');

  useUserStore.getState().reset();
  useOrganisationStore.getState().reset();
  useJackStore.getState().reset();
  useAppStore.getState().logOut();
  usePushStore.getState().setPushStatus('pristine');
}
