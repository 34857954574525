import create from 'zustand';
import { persist } from 'zustand/middleware';

import { JackInfo } from 'types/jack';

const default_jack_info: JackInfo = {
  address_city: '',
  address_street: '',
  address_postcode: '',
  business_name: '',
  jack_price_1: 35,
  jack_price_2: 60,
  jack_price_3: 90,
  jack_travel_price: 35,
  jack_travel_price_2: 55,
  jack_travel_price_3: 60,
  jack_type: 'junior',
  prices_visible: false,
  status: 'online',
  fk_area_id: 1,
  fully_available: false,
  last_intervention_date: '',
  birthday: '',
  iban: '',
  iban_name: '',
  bic: '',
  siret_number: '',
  sellsy_id: '',
  vat_number: '',
  kyc: 'pending',
  stripe_id: '',
  sinao_id: 0,
  prices_filled: false,
  apply_tva: true,
  fee_percentage: 30,
  fee_percentage_2: 30,
  fee_percentage_3: 30,
  travel_fee_percentage: 15,
  invoice_handled: true,
};

type JackInfoStore = JackInfo & {
  setJackInfos: (jack_infos: JackInfo) => void;
  setKYCValidated: () => void;
  setPricesFilled: () => void;
  reset: () => void;
};

export const useJackStore = create<JackInfoStore>()(
  persist(
    set => ({
      ...default_jack_info,
      reset: () => set({ ...default_jack_info }),
      setJackInfos: jack_infos => set(state => ({ ...state, ...jack_infos })),
      setKYCValidated: () => set({ kyc: 'validated' }),
      setPricesFilled: () => set({ prices_filled: true }),
    }),
    { name: 'jack-store' },
  ),
);
