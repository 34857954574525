import * as React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons';

import { useNetworkStore } from 'components/hooks/stores/network';

export type SuccessToastProps = {
  body: React.ReactText | React.ReactElement
  footer?: React.ReactText | React.ReactElement
};

const SuccessToast = ({ body, footer }: SuccessToastProps) => <div className="w-full">
  <div className="font-medium">{body}</div>
  {footer && <div className="text-center mt-2">{footer}</div>}
</div>;

export function triggerSuccessToast(body: React.ReactText | React.ReactElement, footer?: React.ReactText | React.ReactElement) {
  const pending_toast = useNetworkStore.getState().pending_toast;

  if (pending_toast) {
    return toast.update(pending_toast, {
      type: 'success',
      icon: <FontAwesomeIcon icon={faCheckSquare} color='#27ae60' className="text-xl" />,
      render: <SuccessToast body={body} footer={footer} />,
      autoClose: 3000,
      hideProgressBar: false
    });
  }
  return useNetworkStore.getState().setPendingToast(toast.success(<SuccessToast body={body} footer={footer} />, {
    icon: <FontAwesomeIcon icon={faCheckSquare} color='#27ae60' className="text-xl" />,
    onClose: () => useNetworkStore.getState().clearPendingToast()
  }));
}
