import { useUserStore } from 'components/hooks/stores/user';
import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';

const Drafts = lazy(() => import('../drafts/drafts'));
const OpsDashboard = lazy(() => import('../dashboard/ops/ops-dashboard'));
const UserDashboard = lazy(() => import('../dashboard/user/user-dashboard'));
const JackDashboard = lazy(() => import('../dashboard/jack/jack-dashboard'));
const OpsHistory = lazy(() => import('../history/ops/ops-history'));
const UserHistory = lazy(() => import('../history/user/user-history'));
const JackHistory = lazy(() => import('../history/jack/jack-history'));
const JackProfile = lazy(() => import('../profile/jack-profile'));
const UserProfile = lazy(() => import('../profile/user-profile'));
const Equipments = lazy(() => import('../catalog/catalog'));
const Planning = lazy(() => import('../planning/planning'));
const Timeline = lazy(() => import('../timeline/timeline'));
const CheckReport = lazy(() => import('../check-report/check-report'));
const OrganisationsList = lazy(() => import('../organisation/list/list'));
const Organisation = lazy(() => import('../organisation/list/organisation'));
const Inbox = lazy(() => import('../inbox/inbox'));
const Jacks = lazy(() => import('../jacks/jacks-page'));
const MarketPlace = lazy(() => import('../marketplace/marketplace'));
const Availabilities = lazy(() => import('../availabilities/jack-availabilities'));
const Order = lazy(() => import('../order/order'));
const JackOnboardingModal = lazy(() => import('../onboarding/jack'));
const NotFound = lazy(() => import('components/routes/errors/not-found'));

const useSentryRoutes = wrapUseRoutes(useRoutes as any);

const UserRoutes = () => {
  const user_role = useUserStore(user => user.role);
  const children = [
    { index: true, element: <UserDashboard /> },
    { path: 'dashboard', element: <UserDashboard /> },
    { path: 'drafts', element: <Drafts /> },
    { path: 'history', element: <UserHistory /> },
    { path: 'equipments', element: <Equipments /> },
    { path: 'profile', element: <UserProfile /> },
    { path: 'timeline/:service_id', element: <Timeline /> },
    { path: 'check-report/:check_report_id', element: <CheckReport /> },
    { path: 'inbox', element: <Inbox /> },
    {
      path: 'organisations',
      children: [{ path: ':organisation_id', element: <Organisation /> }],
    },
    { path: 'order', element: <Order /> },
    { path: '*', element: <NotFound /> },
  ];

  if (user_role === 'manager') {
    // We muser insert planning and jack routes before the last one
    children.splice(9, 0, { path: 'planning', element: <Planning /> });
    children.splice(9, 0, { path: 'jacks', element: <Jacks /> });
  }

  if (user_role === 'restricted') {
    children.splice(9, 2);
  }

  const routes = useSentryRoutes([
    {
      path: '/',
      children,
    },
    { path: '*', element: <NotFound /> },
  ]);

  return routes;
};

const JackRoutes = () => {
  const routes = useSentryRoutes([
    { path: '/', element: <JackDashboard /> },
    {
      path: '/jack',
      children: [
        { path: 'dashboard', element: <JackDashboard /> },
        { path: 'marketplace', element: <MarketPlace /> },
        { path: 'planning', element: <Planning /> },
        { path: 'timeline/:service_id', element: <Timeline /> },
        { path: 'check-report/:check_report_id', element: <CheckReport /> },
        { path: 'history', element: <JackHistory /> },
        { path: 'inbox', element: <Inbox /> },
        { path: 'jacks', element: <Jacks /> },
        { path: 'profile', element: <JackProfile /> },
        { path: 'availabilities', element: <Availabilities /> },
        { path: 'onboarding', element: <JackOnboardingModal /> },
        { path: '*', element: <NotFound /> },
      ],
    },
    { path: '*', element: <NotFound /> },
  ]);

  return routes;
};

const OpsRoutes = () => {
  const routes = useSentryRoutes([
    { path: '/', element: <OpsDashboard /> },
    {
      path: '/ops',
      children: [
        { path: 'dashboard', element: <OpsDashboard /> },
        { path: 'planning', element: <Planning /> },
        { path: 'equipments', element: <Equipments /> },
        { path: 'timeline/:service_id', element: <Timeline /> },
        { path: 'check-report/:check_report_id', element: <CheckReport /> },
        { path: 'history', element: <OpsHistory /> },
        { path: 'profile', element: <UserProfile /> },
        { path: 'inbox', element: <Inbox /> },
        {
          path: 'organisations',
          children: [
            { path: '', element: <OrganisationsList /> },
            { path: ':organisation_id', element: <Organisation /> },
          ],
        },
        { path: 'order', element: <Order /> },
        { path: 'jacks', element: <Jacks /> },
        { path: '*', element: <NotFound /> },
      ],
    },
    { path: '*', element: <NotFound /> },
  ]);

  return routes;
};

export { UserRoutes, JackRoutes, OpsRoutes };
