import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

export function generateQueryParams(params?: { [key: string]: any }, keys?: string[]) {
  if (!params) {
    return '';
  }

  const query_params = keys!
    .map(key => {
      if (!isNil(params[key])) {
        const param = Array.isArray(params[key]) ? params[key].join(',') : params[key];

        return param.toString().length > 0 ? `${key}=${param}` : undefined;
      }
      return undefined;
    })
    .filter(param => param !== undefined);

  return query_params.length > 0 ? `?${query_params.join('&')}` : '';
}

export function generateFormData(values: any, upload_pictures_mapping?: { [key: string]: string }) {
  const form_data = new FormData();

  for (const [key, value] of Object.entries(values)) {
    if (value !== undefined) {
      if (key.includes('upload') && value && isArray(value)) {
        value.forEach(picture => {
          if (picture.name) {
            // is filereader
            form_data.append(key, picture);
          } else if (upload_pictures_mapping) {
            form_data.append(`${upload_pictures_mapping[key]}[]`, picture);
          }
        });
      } else if (value && typeof value === 'object' && !isArray(value)) {
        for (const [sub_key, sub_value] of Object.entries(value)) {
          form_data.append(`${key}[${sub_key}]`, sub_value);
        }
      } else if (value && isArray(value)) {
        value.forEach((v: any) => form_data.append(`${key}[]`, v));
      } else {
        form_data.append(key, value as any);
      }
    }
  }
  return form_data;
}
