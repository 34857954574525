import * as React from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { useUserStore } from 'components/hooks/stores/user';
import { useOrganisationStore } from 'components/hooks/stores/organisation';
import { useRocketChatStore } from 'components/hooks/stores/rocketchat';

import { SidenavState } from '../navigation';
import { generateMenu } from './menu';
import TabbarEntry from './tabbar-entry';
import styles from './tabbar.module.css';

type TabbarProps = {
  state: SidenavState;
  dispatch: React.Dispatch<any>;
};

const Tabbar = ({ state, dispatch }: TabbarProps) => {
  const { user_type, user_role } = useUserStore(user => ({ user_type: user.type, user_role: user.role }), shallow);
  const organisation = useOrganisationStore();
  const location = useLocation();
  const has_notifications = useRocketChatStore(state => state.has_notifications);
  const prefix = user_type === 'pro' ? '' : `/${user_type}`;
  const menu = generateMenu({ type: user_type, role: user_role }, organisation, true);
  const tabbar_size = user_type !== 'jack' ? menu.length + 1 : menu.length;

  function getInboxEntryIdx() {
    return menu.findIndex(entry => entry.path === 'inbox');
  }
  const inbox_entry_idx = getInboxEntryIdx();

  // Notifications spot on sidenav
  if (inbox_entry_idx !== -1) {
    menu[inbox_entry_idx].has_notifications = has_notifications;
  }

  return (
    <Translate>
      {({ translate }: { translate: TranslateFunction }) => (
        <div
          className={classNames({
            [styles['tabbar']]: true,
            [styles['tabbar-staging']]: import.meta.env.MODE && import.meta.env.MODE === 'integration',
            [styles['tabbar-hidden']]: state.mobile_visible,
          })}
        >
          <nav className={styles['tabbar-content']}>
            <ul
              className={classNames({
                grid: true,
                'grid-cols-3': tabbar_size === 3,
                'grid-cols-4': tabbar_size === 4,
                'grid-cols-5': tabbar_size === 5,
              })}
            >
              {user_type !== 'jack' && (
                <li className={styles['menu-item']} data-cy="burger-toggle" key="tabbar-burger" onClick={() => dispatch({ type: 'DISPLAY_MOBILE' })}>
                  <div className={styles['menu-item-link']}>
                    <div
                      className={classNames({
                        [styles['burger-btn']]: true,
                        [styles['burger-btn-active']]: state.mobile_visible,
                      })}
                    >
                      <span></span>
                    </div>
                    <div className={styles['menu-item-name']}>{translate('header.menu') as any}</div>
                  </div>
                </li>
              )}
              {menu.map(entry => (
                <TabbarEntry {...entry} prefix={prefix} translate={translate} location={location} />
              ))}
            </ul>
          </nav>
        </div>
      )}
    </Translate>
  );
};

export default Tabbar;
