import React from 'react';
import { request } from 'api/request';
import AppConfig from 'config';
import sha1 from 'crypto-js/sha1';

import { useRocketChatStore } from 'components/hooks/stores/rocketchat';

export type RequestParams = {
  intervention_id: number,
  group_type?: 'users' | 'jacks' | 'ops',
}

export function initGroup(body: RequestParams) {
  return request({
    path: '/rocket-chat/init-group',
    method: 'POST',
    body
  });
};

export function joinGroup({ room_id, user_id }: { room_id: string, user_id?: React.ReactText }) {
  return request({
    path: '/rocket-chat/join-group',
    method: 'POST',
    body: {
      roomId: room_id,
      user_id
    }
  });
};

export function getInterventionGroup(params: RequestParams) {
  return request({
    path: '/rocket-chat/group',
    method: 'GET',
    params,
  });
};

export function archiveGroup(room_id: string) {
  return request({
    path: '/rocket-chat/archive-group',
    method: 'POST',
    body: {
      roomId: room_id,
    }
  });
};

export async function rocketChatLogin(user_email: string) {
  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/login',
    method: 'POST',
    body: {
      user: `${user_email}`,
      password: sha1(`${user_email},m3rc1j4ck`).toString(),
    },
  });
}

export async function setStatus(status: 'online' | 'away' | 'busy' | 'offline') {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/users.setStatus',
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: { status },
  });
}

export async function getMyGroups() {
  const rocket_user = useRocketChatStore.getState().rc_user!;
  const params = new URLSearchParams(`sort={"lm":-1}`);

  try {
    const res = await request({
      baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
      path: '/groups.list',
      method: 'GET',
      params,
      headers: {
        'X-Auth-Token': `${rocket_user.authToken}`,
        'X-User-Id': `${rocket_user.userId}`
      },
    });

    return res
  } catch (err: any) {
    if (err.error === 'Resource not found') {
      return {
        count: 0,
        groups: [],
        offset: 0,
        success: true,
        total: 0,
      };
    }

    throw err;
  }
}

export async function getAllGroups(archived: boolean = false) {
  const rocket_user = useRocketChatStore.getState().rc_user!;
  const params = new URLSearchParams(`sort={"lm":-1}&query={"archived":${archived ? '{"$eq":true}' : '{"$ne":true}'}}`);

  try {
    const res = await request({
      baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
      path: '/groups.listAll',
      method: 'GET',
      params,
      headers: {
        'X-Auth-Token': `${rocket_user.authToken}`,
        'X-User-Id': `${rocket_user.userId}`
      },
    });

    return res
  } catch (err: any) {
    if (err.error === 'Resource not found') {
      return {
        count: 0,
        groups: [],
        offset: 0,
        success: true,
        total: 0,
      };
    }

    throw err;
  }
}


export async function unarchiveGroup({ room_id }: { room_id: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/groups.unarchive',
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: {
      roomId: room_id,
    },
  });
}

export async function postMessage({ room_id, text }: { room_id: string, text: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/chat.postMessage',
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: {
      roomId: room_id,
      text,
    },
  });
}

export async function setSubscriptionsRead({ room_id }: { room_id: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/subscriptions.read',
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: {
      rid: room_id,
    },
  });
}

export async function uploadRoomDocument({ room_id, file, msg }: { room_id: string, file: File, msg?: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  const upload_form = new FormData();

  upload_form.append('file', file);

  if (msg) {
    upload_form.append('msg', msg);
  }

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: `/rooms.upload/${room_id}`,
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: upload_form
  });
}

export async function getLiveChatRooms() {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/livechat/rooms',
    method: 'GET',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    params: {
      open: true
    }
  });
}

export async function getLivechatUserInfo(token: string) {
  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: `/livechat/visitor/${token}`,
    method: 'GET'
  });
}

export async function getUserInfo() {
  const rocket_user = useRocketChatStore.getState().rc_user!;
  const params = new URLSearchParams(`fields={"userRooms":1}&userId=${rocket_user.userId}`);

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/users.info',
    method: 'GET',
    params,
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
  });
}

export async function leaveGroup({ room_id }: { room_id: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/groups.leave',
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: { roomId: room_id }
  });
}

export async function closeLivechat({ room_id, user_token }: { room_id: string, user_token: string }) {
  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/livechat/room.close',
    method: 'POST',
    body: {
      rid: room_id,
      token: user_token
    }
  });
}

export async function getLivechatMessages(room_id: string, user_token: string) {
  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: `/livechat/messages.history/${room_id}`,
    method: 'GET',
    params: {
      token: user_token
    }
  });
}

export async function getInquiryByRoomId({ room_id }: { room_id: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/livechat/inquiries.getOne',
    method: 'GET',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    params: {
      roomId: room_id,
    }
  });
}

export async function takeInquiry({ inquiryId }: { inquiryId: string }) {
  const rocket_user = useRocketChatStore.getState().rc_user!;

  return request({
    baseURL: `https://${AppConfig.rocket_chat_api.baseURL}/api/v1`,
    path: '/livechat/inquiries.take',
    method: 'POST',
    headers: {
      'X-Auth-Token': `${rocket_user.authToken}`,
      'X-User-Id': `${rocket_user.userId}`
    },
    body: {
      inquiryId,
    }
  });
}
