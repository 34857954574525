import * as React from 'react';
import { Translate } from 'react-localize-redux';
import shallow from 'zustand/shallow';

import config from 'config';
import { termsAction } from 'api/organisations';
import { useUserStore } from 'components/hooks/stores/user';
import { useOrganisationStore } from 'components/hooks/stores/organisation';
import Modal from 'components/widgets/modal';
import Button from 'components/widgets/button/button';
import { useMJMutation } from 'components/hooks/api/query';

import styles from './terms.module.css';
import { update } from 'lodash';

type TermsModalProps = {
  onTermUpdate: (status: boolean) => any
};

const TermsModal = ({ onTermUpdate }: TermsModalProps) => {
  const { organisation_id, acceptOrganisationTerms, refuseOrganisationTerms, updateHasToAcceptTerms }= useOrganisationStore(orga => ({
    organisation_id: orga.id,
    acceptOrganisationTerms: orga.acceptTerms,
    refuseOrganisationTerms: orga.refuseTerms,
    updateHasToAcceptTerms: orga.updateHasToAcceptTerms,
  }), shallow);
  const { updateUserCanOrder } = useUserStore(user => ({
    updateUserCanOrder: user.updateCanOrder,
  }), shallow);
  const mutation = useMJMutation(termsAction, {
    onSuccess: (data: any, variables: any) => {
      if (variables.status === 'accept') {
        updateUserCanOrder(true);
        acceptOrganisationTerms();
        updateHasToAcceptTerms(false);
      }
      else {
        updateUserCanOrder(false);
        refuseOrganisationTerms();
        updateHasToAcceptTerms(true);
      }
      onTermUpdate(variables.status === 'refuse' ? true : false);
    },
    success_toast: {
      body: 'Votre choix a bien été enregistré'
    }
  });

  async function onActionClick(status: 'accept' | 'refuse') {
    return mutation.mutate({ organisation_id, status } as any);
  }

  return <Modal title='terms.title' onClose={() => {}}>
    <p>
      <Translate id='terms.text' />
    </p>
    <a className={styles['terms']} href={config.terms_url} target="_blank" rel="noopener noreferrer">
      <Translate id='terms.terms_link' />
    </a>
    <div className={styles['action-btn']}>
      {/* <Button
        size='big'
        color='error'
        text={<Translate id='terms.decline' />}
        onClick={() => onActionClick('refuse')} /> */}
      <Button
        size='big'
        color='primary'
        text={<Translate id='terms.accept' />}
        onClick={() => onActionClick('accept')} />
    </div>
  </Modal>;
}

export default TermsModal
