import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { getApp, getApps, initializeApp } from 'firebase/app';
import RemoteConfig, { fetchAndActivate, getRemoteConfig, getAll, getValue } from 'firebase/remote-config';
import { Cipher } from 'crypto';
import AppConfig from 'config';

const queryFirebase = async (collectionName: string, docName: string, data: any) => {
    const app = getApp();
    console.log(app);
    const db = getFirestore(app);
    console.log(db);
    const querySnapshot = await getDocs(collection(db, collectionName));
    querySnapshot.forEach((doc) => {
        console.log(doc.id, ' => ', doc.data());
    });
}

export const getTermsLastUpdate = async () => {
    let app;
    if (getApps().length === 0) {
        app = initializeApp(AppConfig.firebase.global);
    } else {
        app = getApp();
    }
    console.log('here is app');
    const remoteConfig = getRemoteConfig(app);
    console.log('setting timeout');

    const init = JSON.parse(getValue(remoteConfig, 'init').asString());
    console.log('init', new Date(init.init.terms_updated));
    return init.init.terms_updated;
}

export default queryFirebase;