import * as React from 'react';
import { Translate } from 'react-localize-redux';
import * as Sentry from '@sentry/react';

import jack from 'components/widgets/header/jack.png';
import { useUserStore } from 'components/hooks/stores/user';

import packageInfo from '../../../../package.json';
import styles from './error-boundary.module.css';

type BoundaryState = {
  error: boolean
}

class ErrorBoundary extends React.Component<any, BoundaryState> {
  public state: BoundaryState = {
    error: false
  };

  public static getDerivedStateFromError(_: Error): BoundaryState {
    return { error: true };
  }

  public componentDidCatch(error: Error) {
    const user = useUserStore.getState();

    Sentry.withScope((scope) => {
      scope.setUser({
        id: user.id?.toString(),
        username: `${user.first_name} ${user.last_name}`,
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return <div className={styles['error-boundary']}>
        <div className={styles['section-top']}>
          <img className={styles['jack-logo']} alt="jack" src={jack} />
        </div>
        <div className={styles['section-bottom']}>
          <h1><Translate id='boundary.title' /></h1>
          <h3><Translate id='boundary.tips_1' /></h3>
          <h3><Translate id='boundary.tips_2' /></h3>
          <h3><Translate id='boundary.tips_3' /> <a className={styles['link']} href="mailto:hello@mercijack.co">hello@mercijack.co</a></h3>
        </div>
        <div className={styles['app-version-section']}>
          Version de l'application : {packageInfo.version}
        </div>
      </div>;
    }
    return this.props.children;
  }
};

export default ErrorBoundary;
