import { RocketGroup, RocketLoginUser } from 'types/rocket-chat';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

export function computeGroupSearchField(group: RocketGroup) {
  const orga_name = get(group, 'customFields.organisation.name', '');
  const orga_id = get(group, 'customFields.organisation.id', '');

  return `${group._id} ${group.fname} ${orga_name} ${orga_id}`.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export function formatGroupSearch(group: RocketGroup[]) {
  return group.map(group => ({
    ...group,
    search_field: computeGroupSearchField(group)
  }));
};

export function insertLiveGroups(rc_user: RocketLoginUser, live_groups: RocketGroup[], my_groups: RocketGroup[], all_groups: RocketGroup[]) {
  if (live_groups) {
    live_groups.forEach(group => {
      if (group.closedAt) {
        return;
      }
      group.origin = 'live';
      if (group.servedBy && group.servedBy._id === rc_user.userId) {
        my_groups.push(group);
      }
      else {
        all_groups.push(group);
      }
    });
  }
}

export function enrichMyGroups(my_groups: RocketGroup[], grouped_rooms: { [key: string]: any }, rc_user: RocketLoginUser) {
  let unread_count = 0;

  my_groups.forEach(group => {
    group.origin = group.origin || 'mine';
    if (grouped_rooms[group._id]?.unread > 0 || group.waitingResponse) {
      if (group.lastMessage?.u._id !== rc_user.userId) {
        group.unread = true;
        unread_count += 1;
      } else {
        group.unread = false;
      }
    }
    else {
      group.unread = false;
    }
  });
  return { unread_count, my_groups: orderBy(formatGroupSearch(my_groups), ['unread', 'lm'], ['desc', 'desc']) };
}

export function enrichArchivedGroups(archived_groups: RocketGroup[], grouped_rooms: { [key: string]: any }) {
  let unread_archived_count = 0;

  archived_groups.forEach(group => {
    group.origin = group.origin || 'mine';
    if (grouped_rooms[group._id]?.unread > 0 || group.waitingResponse) {
      group.unread = true;
      unread_archived_count += 1;
    }
    else {
      group.unread = false;
    }
  });
  return { unread_archived_count, archived_groups: orderBy(formatGroupSearch(archived_groups), ['unread', 'lm'], ['desc', 'desc']) };
}

export function enrichAllGroups(all_groups: RocketGroup[]) {
  let no_ops_groups_count = 0;

  if (all_groups) {
    all_groups.forEach((group: RocketGroup) => {
      group.origin = group.origin || 'all';
      if ((group.origin === 'live' && group.usersCount === 1 && !group.archived) || (group.origin !== 'live' && group.usersCount < 3 && group.fname.search('ops') === -1 && !group.archived)) { // no_ops makes no sense for ops group
        group.has_no_ops = true;
        no_ops_groups_count += 1;
      }
      else {
        group.has_no_ops = false;
      }
    });
  }
  return { no_ops_groups_count, all_groups: orderBy(formatGroupSearch(all_groups), ['has_no_ops', 'lm'], ['desc', 'desc']) };
}
