import { Intervention } from 'types/intervention';
import { Actions } from './rules';

export const default_static_manager_rules: Actions['static'] = [
  'dashboard:create_service',
  'dashboard:create_address',
  'addresses:create',
  'header:trial',
  'jacks:edit',
  'jacks:edit_skills',
  'intervention:user_cta',
  'timeline:client_price',
  'timeline:change_date',
  'timeline:link_estimates',
  'organisation:create_address',
  'organisation:create_user',
  'organisation:financial_stats',
  'panel:user',
  'panel:check_estimate',
  'planning:ics',
  'dashboard:tags_type',
  'check_list:ko_list',
];

const timeline_rules: Actions['dynamic'] = {
  'timeline:edit_contact': ({ intervention }) => {
    return intervention;
  },
  'timeline:change_address': ({ intervention }) => {
    return intervention && !['ongoing', 'started', 'ended'].includes(intervention.status);
  },
  'timeline:view_equipments': ({ service }) => {
    return service && !['pending-technical-validation', 'pending-validation', 'pending-renewal'].includes(service.equipment_status);
  },
  'timeline:edit_service': ({ service: intervention }) => {
    return intervention && ['ended', 'partially-ended', 'pending-ops-review'].includes(intervention.status);
  },
  'timeline:assign_jack': ({ intervention }) => {
    return intervention && !intervention.jack && !['ongoing', 'started', 'ended', 'pending-ops-review'].includes(intervention.status);
  },
  'timeline:change_jack': ({ intervention }) => {
    return intervention && intervention.jack && !['ongoing', 'started', 'ended', 'pending-ops-review'].includes(intervention.status);
  },
  'timeline:close_dispute': ({ intervention }) => {
    return intervention && intervention.dispute_status === 'started';
  },
};

export const default_dynamic_manager_rules: Actions['dynamic'] = {
  ...timeline_rules,
  'report:sign': ({ is_signed }) => {
    return !is_signed;
  },
  'drafts:transform': ({ addresses }) => {
    return addresses && addresses.length > 0;
  },
  'panel:invoice': ({ service }) => {
    return service.invoice_sellsy_id && service.invoice_sellsy_id !== -1 && service.invoice_status !== 'draft';
  },
  'panel:cancel_intervention': ({ intervention }: { intervention: Intervention }) => {
    return (
      intervention.status === 'expired' ||
      (['pending', 'planned'].includes(intervention.status) &&
        !intervention.estimate_sellsy_id &&
        !intervention.product_estimate_sellsy_id &&
        !intervention.estimate_sinao_id)
    );
  },
  'panel:renew_estimate': ({ intervention }: { intervention: Intervention }) => {
    return (
      intervention.status === 'expired' &&
      (intervention.estimate_sellsy_id !== null || intervention.product_estimate_sellsy_id !== null || intervention.estimate_sinao_id !== null)
    );
  },
  'panel:check_estimate': ({ intervention }: { intervention: Intervention }) => {
    return !!intervention.estimate_sellsy_id || !!intervention.product_estimate_sellsy_id || !!intervention.estimate_sinao_id || !!intervention.fk_estimate_id;
  },
  'panel:edit_service': ({ service }) => {
    return service && !['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
  },
  'panel:dispute': ({ intervention }: { intervention: Intervention }) => {
    return intervention.slots.every(slot => !slot.fk_mk_invoice_id) && !intervention.dispute_status;
  },
};
