import { FormattedIntervention, Intervention } from 'types/intervention';
import { Actions } from './rules';

export const default_static_ops_rules: Actions['static'] = [
  'dashboard:create_service',
  'organisation:create_address',
  'organisation:create_user',
  'organisation:financial_stats',
  'organisation:see_invoice',
  'organisation:filter_contracts',
  'organisation:duplicate_contract',
  'organisation:create_contract',
  'organisation:management_info',
  'addresses:create',
  'planning:use_filters',
  'planning:display_jack_name',
  'equipments:see_prices',
  'timeline:pick_solution',
  'timeline:client',
  'timeline:client_price',
  'timeline:jack_price',
  'timeline:delivery_info_detailed',
  'timeline:ops_assigned',
  'timeline:view_equipments',
  'timeline:edit_estimate',
  'timeline:availabilities',
  'timeline:update_intervention',
  'timeline:commission',
  'intervention:ops_cta',
  'inbox:all_conversations',
  'panel:user',
  'panel:jack',
  'panel:see_invoice',
  'check_list:ko_list',
  'jacks:edit',
  'jacks:set_availabilities',
  'organisation:update_equipment',
  'jacks:edit_skills',
  'jack_contracts:assign',
  'blacklist:update',
  'users:password',
];

export const default_dynamic_ops_rules: Actions['dynamic'] = {
  'dashboard:ops_assigned': ({ card_entity }) => {
    return card_entity && card_entity.ops;
  },
  'dashboard:delivery': ({ service }) => {
    return (service.with_live_delivery || service.delivery_status) && service.status !== 'ended';
  },
  'organisation:view_sellsy_account': ({ organisation }) => {
    return !organisation.children || organisation.children.length === 0;
  },
  'organisation:update_contract_task': ({ contract }) => {
    return contract && contract.status === 'draft';
  },
  'organisation:reprocess_invoice': ({ organisation }) => {
    return organisation && organisation.subscription_type === 'all-inclusive';
  },
  'timeline:internal_note': ({ service }) => {
    return service && service.internal_note;
  },
  'timeline:edit_contact': ({ intervention }) => {
    return !!intervention;
  },
  'timeline:change_address': ({ intervention }) => {
    return intervention && intervention.meta_type === 'curative' && !['ongoing', 'started', 'ended'].includes(intervention.status);
  },
  'timeline:order_equipments': ({ intervention }: { intervention: Intervention }) => {
    return (
      !intervention.estimate_sellsy_id &&
      !intervention.product_estimate_sellsy_id &&
      !intervention.estimate_sinao_id &&
      ['pending-technical-validation', 'started', 'ongoing'].includes(intervention.status)
    );
  },
  'timeline:equipments_validation': ({ intervention }: { intervention: Intervention }) => {
    return (
      intervention &&
      ['pending-technical-validation'].includes(intervention.status) &&
      !!intervention.product_estimate_sellsy_id &&
      intervention.estimate_status === 'draft' &&
      intervention.products &&
      intervention.products.length > 0
    );
  },
  'timeline:estimate_validation': ({ intervention }: { intervention: Intervention }) => {
    return (
      intervention &&
      ['pending-technical-validation'].includes(intervention.status) &&
      (!!intervention.product_estimate_sellsy_id || !!intervention.estimate_sellsy_id || !!intervention.estimate_sinao_id) &&
      intervention.estimate_status === 'draft'
    );
  },
  'timeline:equipments_delivery': ({ service }) => {
    return (
      service &&
      (service.status === 'pending-material' ||
        (service.status === 'pending-technical-validation' && !service.estimate_sellsy_id && !service.product_estimate_sellsy_id && !service.estimate_sinao_id))
    );
  },
  'timeline:remove_estimate': ({ intervention }: { intervention: Intervention }) => {
    return (
      intervention &&
      (!!intervention.product_estimate_sellsy_id || !!intervention.estimate_sellsy_id || !!intervention.estimate_sinao_id) &&
      !['accepted', 'invoiced'].includes(intervention.estimate_status) &&
      !['ongoing', 'started', 'ended'].includes(intervention.status)
    );
  },
  'timeline:edit_service': ({ service }) => {
    return service && service.status !== 'ended';
  },
  'timeline:edit_service_title': ({ service }) => {
    return service && service.title;
  },
  'timeline:assign_jack': ({ intervention }) => {
    return intervention && !intervention.jack && !['ongoing', 'started', 'ended', 'pending-ops-review'].includes(intervention.status);
  },
  'timeline:add_slot': ({ intervention }) => {
    return (
      intervention &&
      intervention.meta_type === 'curative' &&
      !intervention.estimate_sinao_id &&
      ['pending-technical-validation', 'pending-material', 'pending-delivery', 'pending', 'planned'].includes(intervention.status)
    );
  },
  'timeline:change_jack': ({ intervention }) => {
    return intervention && intervention.jack && !['ongoing', 'started', 'ended', 'pending-ops-review'].includes(intervention.status);
  },
  'timeline:delete_jack': ({ slot }) => {
    return slot && ['available', 'pending', 'accepted'].includes(slot.status);
  },
  'timeline:change_date': ({ intervention }) => {
    return (
      intervention &&
      !['ongoing', 'started', 'ended', 'pending-technical-validation', 'pending-material', 'pending-client-signature', 'pending-ops-review'].includes(
        intervention.status,
      )
    );
  },
  'timeline:update_date': ({ slot }) => {
    return slot && slot.status === 'ended';
  },
  'timeline:delivery_price': ({ delivery }) => {
    return delivery && delivery.price;
  },
  'timeline:generate_invoice': ({ intervention }: { intervention: FormattedIntervention }) => {
    return intervention && intervention.status === 'ended' && intervention.meta_type === 'curative';
  },
  'timeline:ops_grade': ({ service }) => {
    return service && service.ops_difficulty_grade;
  },
  'timeline:jack_grade': ({ service }) => {
    return service && service.jack_difficulty_grade;
  },
  'timeline:update_estimate': ({ service }) => {
    return (
      (service.estimate_sellsy_id || service.estimate_sinao_id || (service.product_estimate_sellsy_id && !service.products)) &&
      !['invoiced', 'expired'].includes(service.estimate_status)
    );
  },
  'timeline:jack_availabilities': ({ intervention }) => {
    return !!intervention.fk_jack_id && intervention.jack.role !== 'restricted';
  },
  'timeline:link_estimates': ({ intervention }) => {
    return (
      ['pending', 'planned', 'pending-technical-validation', 'ongoing', 'started', 'pending-ops-review', 'ended'].includes(intervention.status) &&
      !intervention.estimate_sellsy_id &&
      !intervention.estimate_sinao_id &&
      !intervention.product_estimate_sellsy_id &&
      intervention.type !== 'diagnosis'
    );
  },
  'timeline:update_jack_price': ({ intervention }) => {
    return intervention && intervention.meta_type === 'preventive';
  },
  'panel:cancel_intervention': ({ intervention }: { intervention: Intervention }) => {
    return !['ongoing', 'started', 'ended', 'canceled', 'pending-ops-review'].includes(intervention.status);
  },
  'panel:renew_estimate': ({ intervention }: { intervention: Intervention }) => {
    return (
      intervention.status === 'expired' &&
      (intervention.estimate_sellsy_id !== null || intervention.product_estimate_sellsy_id !== null || intervention.estimate_sinao_id !== null)
    );
  },
  'panel:check_estimate': ({ intervention }: { intervention: Intervention }) => {
    return !!intervention.estimate_sellsy_id || !!intervention.product_estimate_sellsy_id || !!intervention.estimate_sinao_id || !!intervention.fk_estimate_id;
  },
  'panel:edit_service': ({ service }) => {
    return service && !['ended', 'partially-ended', 'pending-ops-review'].includes(service.status);
  },
  'panel:cancel_ongoing': ({ intervention }) => {
    return intervention && intervention.status === 'ongoing';
  },
  'panel:dispute': ({ intervention }: { intervention: Intervention }) => {
    return intervention.slots.every(slot => !slot.fk_mk_invoice_id) && !intervention.dispute_status;
  },
  'timeline:close_dispute': ({ intervention }) => {
    return intervention && intervention.dispute_status === 'started';
  },
  'timeline:sync_estimate': ({ intervention }) => {
    return (
      !!intervention.estimate_sinao_id &&
      ['pending-technical-validation', 'pending-material', 'pending-client-signature', 'pending', 'planned'].includes(intervention.status)
    );
  },
};
