import { Intervention } from 'types/intervention';
import { Actions } from './rules';

const default_jack_rules: Actions = {
  static: ['intervention:jack_cta', 'timeline:client', 'timeline:jack_price', 'planning:ics', 'panel:jack', 'panel:version'],
  dynamic: {
    'check_report:undo': ({ check_report }) => {
      return check_report && check_report.status === 'started';
    },
    'check_report:report_actions': ({ check_report }) => {
      return check_report && check_report.status === 'started';
    },
    'panel:cancel_ongoing': ({ intervention }) => {
      return intervention && intervention.status === 'accepted';
    },
    'panel:check_estimate': ({ intervention }: { intervention: Intervention }) => {
      return (
        !!intervention.estimate_sellsy_id || !!intervention.product_estimate_sellsy_id || !!intervention.estimate_sinao_id || !!intervention.fk_estimate_id
      );
    },
  },
};

export default default_jack_rules;
