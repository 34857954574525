import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize, InitializePayload, SingleLanguageTranslation } from 'react-localize-redux';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { TextZoom } from '@capacitor/text-zoom';
import { Capacitor } from '@capacitor/core';

import { StoreApp } from 'types/store';
import Auth from 'components/routes/auth';
import LoadingPage from 'components/widgets/loading';
import ErrorBoundary from 'components/widgets/error-boundary';
import AnimatedPanel from 'components/widgets/panel/animated-panel';
import { PanelContext } from 'components/contexts/panel';
import { useAppStore } from 'components/hooks/stores/app';

import fr from 'translations/fr.json';
import en from 'translations/en.json';

import Navigation from './navigation';
import styles from './layout.module.css';

const Login = React.lazy(() => import('components/routes/login'));
const Register = React.lazy(() => import('components/routes/register'));
const StartReset = React.lazy(() => import('components/routes/password/start-reset'));
const ResetPassword = React.lazy(() => import('components/routes/password/reset-password'));
const BackSoon = React.lazy(() => import('components/routes/backsoon'));

type LayoutProps = {
  initialize: (payload: InitializePayload) => void,
  addTranslationForLanguage: (
    translation: SingleLanguageTranslation,
    language: string
  ) => void;
};

function reducer(state: any, { type, payload }: any) {
  switch (type) {
    case 'INITIALIZE_PANEL':
      return {
        ...state,
        is_panel_visible: true,
        with_header_shadow: payload.with_header_shadow,
        sections: payload.sections,
        title: payload.title,
        title_icon: payload.title_icon,
        type: payload.type,
        link: payload.link
      };
    case 'CLOSE_PANEL':
      return {
        ...state,
        is_panel_visible: false,
        sections: [],
        title: ''
      };
    default:
      throw new Error();
  }
}

const Layout = (props: any) => {
  const language = useAppStore((state: StoreApp) => state.language);
  const { initialize, addTranslationForLanguage }: LayoutProps = props;
  const [state, dispatch] = React.useReducer(reducer, {
    with_header_shadow: false,
    is_panel_visible: false,
    sections: [],
    title: '',
  });

  React.useEffect(() => {
    language === 'en' ? moment.locale('en') : moment.locale('fr');

    initialize({
      languages: ['fr', 'en'],
      options: {
        renderToStaticMarkup,
        defaultLanguage: language || 'fr'
      }
    });
    addTranslationForLanguage(en, 'en');
    addTranslationForLanguage(fr, 'fr');
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    const platform = Capacitor.getPlatform();

    if (platform === 'android') {
      async function setTextZoom() {
        await TextZoom.set({ value: 1 });
      }
      setTextZoom();
    }
  }, []);

  return <div className={styles['container']}>
    <Navigation />
    <main>
      <ErrorBoundary>
        <React.Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path="backsoon" element={<BackSoon />} />
            <Route path="login" element={<BackSoon />} />
            <Route path="register" element={<BackSoon />} />
            <Route path="password">
              <Route path="reset" element={<StartReset />} />
              <Route path="new" element={<ResetPassword />} />
            </Route>
            <Route path="*" element={
              <PanelContext.Provider value={{ state, dispatch }}>
                <Auth />
              </PanelContext.Provider>
            } />
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
      <AnimatedPanel
        title={state.title}
        sections={state.sections}
        type={state.type}
        is_panel_visible={state.is_panel_visible}
        with_header_shadow={state.with_header_shadow}
        link={state.link}
        closePanel={() => dispatch({ type: 'CLOSE_PANEL' })} />
      <ToastContainer
        limit={2}
        theme='light'
        position="bottom-left"
        autoClose={3000}
        closeButton={false}
        pauseOnFocusLoss={false}
        className={styles['toast-wrapper']}
        bodyClassName={styles['toast-body']} />
    </main>
  </div>;
};

export default withLocalize(Layout);
