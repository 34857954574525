import * as React from 'react';

import { ACLRules, ACL, Action } from './rules';
import rules from './rules';
import { useUserStore } from 'components/hooks/stores/user';

type CanProps = {
  perform: Action,
  data?: any,
  yes?: () => React.ReactElement,
  no?: () => React.ReactElement
};

export const check = (action: Action, data?: any) => {
  const user_role: keyof ACL = useUserStore.getState().role;
  const user_type: keyof ACLRules = useUserStore.getState().type;
  const permissions = rules[user_type][user_role];

  if (!permissions) {
    return false;
  }
  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }
  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = ({
  perform, data,
  yes = () => <React.Fragment></React.Fragment>,
  no = () => <React.Fragment></React.Fragment>
}: CanProps): React.ReactElement => check(perform, data) ? yes() : no();

export default Can;
