import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Translate } from 'react-localize-redux';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import MJBaseModal, { MJBaseModalProps } from './base';
import styles from './modal.module.css';

type ModalProps = MJBaseModalProps & {
  title?: string | React.ReactNode;
};

const MJModal = ({ title, children, behind_panel = false, prevent_close_from_blur = false, onClose }: ModalProps) => {
  const scroll_lock_handler = React.useCallback((node: HTMLElement | null) => {
    if (node) {
      disableBodyScroll(node);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <MJBaseModal prevent_close_from_blur={prevent_close_from_blur} behind_panel={behind_panel} onClose={onClose}>
      <div className={styles['modal-content']}>
        <div className={styles['modal-header']}>
          {title && typeof title === 'string' && (
            <h3 className={styles['modal-title']}>{title.match(/^[a-zA-Z_1-9]+\.[[a-zA-Z_.1-9]+$/) ? <Translate id={title} /> : title}</h3>
          )}
          {title && typeof title !== 'string' && <div className={styles['modal-title']}>{title}</div>}
          {onClose && (
            <div className={styles['close-btn-wrapper']}>
              <FontAwesomeIcon icon={faTimes} className={styles['close-btn']} onClick={onClose} />
            </div>
          )}
        </div>
        <div ref={scroll_lock_handler} className={styles['modal-body']}>
          <div className={styles['content']}>{children}</div>
        </div>
      </div>
    </MJBaseModal>
  );
};

export default MJModal;
