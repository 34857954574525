import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { TranslateFunction } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from 'history';

import { useMenuStore } from 'components/hooks/stores/menu';

import { Entry } from './menu';
import styles from './tabbar-entry.module.css';

type TabbarEntryProps = {
  prefix: string;
  translate: TranslateFunction;
  location: Location;
} & Entry;

const TabbarEntry = ({ id, cypress_id, name, path, location, has_notifications, icon, prefix, translate, onClick }: TabbarEntryProps) => {
  const source = useMenuStore(store => store.source);
  const content = React.useMemo(
    () => (
      <React.Fragment>
        <FontAwesomeIcon icon={icon} className={styles['menu-item-icon']} />
        <div className={styles['menu-item-name']}>{translate(name) as any}</div>
        {has_notifications && <span className={`animate__animated animate__pulse animate__infinite ${styles['menu-item-notification']}`}></span>}
      </React.Fragment>
    ),
    [icon, name, translate, has_notifications],
  );

  return (
    <li
      id={id}
      data-cy={cypress_id}
      className={classNames({
        [styles['menu-item']]: true,
        [styles['menu-item-selected']]:
          (path && location.pathname.search(path) !== -1) ||
          ((location.pathname.includes('timeline') || location.pathname.includes('check-report') || location.pathname.includes('availabilities')) &&
            path === source) ||
          (path === 'dashboard' && location.pathname === '/'),
      })}
      onClick={onClick}
    >
      {path ? (
        <Link to={`${prefix}/${path}`} className={styles['menu-item-link']}>
          {content}
        </Link>
      ) : (
        <span className={styles['menu-item-link']}>{content}</span>
      )}
    </li>
  );
};

export default TabbarEntry;
