import * as React from 'react';
import { FieldConfig, FieldMetaProps, useField } from 'formik';

import styles from './input.module.css';

type InputProps = {
  no_form?: boolean,
  disabled?: boolean,
} & PlainInputProps;

type PlainInputProps = {
  label?: React.ReactNode,
  placeholder?: string,
  input?: React.InputHTMLAttributes<HTMLInputElement>,
  meta?: FieldMetaProps<any>,
  displayError?: boolean,
  capitalize?: boolean,
  onChange?: any,
  hideError?: boolean
} & FieldConfig<any>;

const PlainInput = ({ input, label, placeholder, meta, displayError, hideError, ...props }: PlainInputProps) => {
  // console.log('meta', meta);
  return (
  <div className={displayError ? "relative w-full mb-4" : "relative w-full mb-6"}>
    { (label || (meta && meta.error))
      &&
      <div className="flex items-center justify-between">
        <label htmlFor={input!.name} className="flex items-center mb-3">
          {label}
          { meta && meta.error
            &&
            <label className="text-error ml-1">*</label>
          }
        </label>
        { meta && meta.touched && meta.error && !hideError 
          &&
          <div className="text-right text-error text-xs mr-2">{meta.error}</div>
        }
      </div>
    }
    { /* @ts-ignore */}
    <input {...input} {...props} value={input && input.value !== undefined ? input.value : ''} placeholder={placeholder} className={styles['mj-input']} />
    {/* {displayError && meta
    && <div className={(meta.touched && meta.error && "text-error text-xs text-right ml-2 mt-1") || "text-error text-xs ml-2 mt-1 invisible"}>{meta.error ? meta.error : 'error'}</div> } */}
  </div>)
};

const FormInput = ({ input, ...props }: PlainInputProps) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const fields = input ? {...input} : {...field}

  return <PlainInput input={fields} meta={meta} {...props} />
};

const Input = ({ no_form = false, ...props }: InputProps) => no_form ? <PlainInput {...props} /> : <FormInput {...props} />;

export default Input;
