import { LocalizeProvider } from 'react-localize-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
// import { ReactQueryDevtools } from 'react-query/devtools'

import Layout from './layout';

type AppProps = {
  queryClient: QueryClient,
};

const App = (props: AppProps) => (
  <BrowserRouter>
    <LocalizeProvider>
      <QueryClientProvider client={props.queryClient}>
        {/*
          hack to prevent error with withLocalize.
          See : https://stackoverflow.com/questions/48240449/type-is-not-assignable-to-type-intrinsicattributes-intrinsicclassattribu
        */}
        <Layout {...props} />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </LocalizeProvider>
  </BrowserRouter>
);

export default App;
