import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { TranslateFunction } from 'react-localize-redux';
import Tooltip from 'rc-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from 'history';

import { useMenuStore } from 'components/hooks/stores/menu';

import { Entry } from './menu';
import styles from './sidenav-entry.module.css';

type SidenavEntryProps = {
  prefix: string,
  translate: TranslateFunction,
  expand: boolean,
  location: Location,
  dispatch?: React.Dispatch<any>
} & Omit<Entry, 'id'>;

const SidenavEntry = ({
  cypress_id, name, path, icon, prefix, external, translate,
  has_notifications, expand, location, onClick, dispatch
}: SidenavEntryProps) => {
  const source = useMenuStore(store => store.source);
  const content = React.useMemo(() => (<React.Fragment>
    { expand
      ?
      <FontAwesomeIcon icon={icon} className={styles['menu-item-icon']} />
      :
      <Tooltip placement='right' overlay={translate(name) as any}>
        <FontAwesomeIcon icon={icon} className={styles['menu-item-icon']} />
      </Tooltip>
    }
    <div data-cy={expand ? `${cypress_id}-link` : undefined} className={classNames({
      [styles['menu-item-name']]: true,
      [styles['menu-item-name-shrink']]: !expand,
      [styles['menu-item-name-expand']]: expand
    })}>
      {translate(name) as any}
    </div>
  </React.Fragment>), [cypress_id, expand, icon, name, translate]);

  function generateEntryContent() {
    const common_props = {
      'data-cy': !expand ? `${cypress_id}-link` : undefined,
      onClick: () => {
        if (dispatch) {
          dispatch({ type: 'HIDE_MOBILE' });
        }
      },
      className: classNames({
        [styles['menu-item-link']]: true,
        [styles['menu-item-link-shrink']]: !expand,
        [styles['menu-item-link-expand']]: expand
      })
    };

    if (path && external) {
      return <a href={path} target='_blank' rel='noopener noreferrer' {...common_props}>
        {content}
      </a>;
    }
    if (path) {
      return <Link to={`${prefix}/${path}`} {...common_props}>
        {content}
      </Link>;
    }
    return <span {...common_props} onClick={() => {}}>
      {content}
    </span>;
  }

  return <li data-cy={cypress_id} className={classNames({
    [styles['menu-item']]: true,
    [styles['menu-item-selected']]: (path && location.pathname.search(path) !== -1) ||
      ((location.pathname.includes('timeline') || location.pathname.includes('check-report')) && path === source) ||
      (path === 'dashboard' && location.pathname === '/'),
    [styles['menu-item-shrink']]: !expand,
    [styles['menu-item-expand']]: expand
  })} onClick={onClick}>
    {generateEntryContent()}
    { has_notifications && !expand
      &&
      <span className={`animate__animated animate__pulse animate__infinite ${styles['menu-item-notification']}`}></span>
    }
    { has_notifications && expand
      &&
      <span className={`animate__animated animate__pulse animate__infinite ${styles['menu-item-notification-expand']}`}></span>
    }
  </li>;
};

export default SidenavEntry;
