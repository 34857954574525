import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import styles from './alt-panel.module.css';

export type AltSection = {
  id: string,
  name: React.ReactNode,
  initial_toggle?: boolean,
  is_sub_section?: boolean
  content: React.ReactNode,
}

type PanelSectionProps = {
  section: AltSection,
};

const AltPanelSection = ({ section }: PanelSectionProps) => {
  const [toggled, setToggled] = React.useState(section.initial_toggle === undefined ? true : section.initial_toggle);

  return <li>
    <label htmlFor={section.id} className={styles['section-name']}>
      <h2 className={styles['section-title']}>{section.name}</h2>
      <div className={styles['toggler']}>
        <FontAwesomeIcon icon={faChevronDown} className={classNames({
          [styles['expand-icon']]: true,
          [styles['expand-icon-toggled']]: toggled
        })} />
      </div>
    </label>
    <input
      type="checkbox"
      name={section.id}
      id={section.id}
      className={styles['section-toggle']}
      checked={toggled}
      onChange={e => setToggled(e.target.checked)} />
    <div className={classNames({
      [styles['sub-section-content-collapsible']]: section.is_sub_section,
      [styles['section-content-collapsible']]: !section.is_sub_section,
    })}>
      {section.content}
    </div>
  </li>;
};

export default AltPanelSection;
