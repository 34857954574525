import { useState } from 'react';
import shallow from 'zustand/shallow';

import Modal from 'components/widgets/modal';
import Input from 'components/widgets/form/input/input';
import { useUserStore } from 'components/hooks/stores/user';
import { updatePassword } from 'api/users';
import { useMJMutation } from 'components/hooks/api/query';
import Button from 'components/widgets/button/button';
import { triggerErrorToast } from 'components/widgets/toast/error';

export default function usePasswordReset() {
  const {
    email,
    has_changed_password,
    setPasswordChanged
  } = useUserStore(user => ({
    email: user.email,
    has_changed_password: user.has_changed_password,
    setPasswordChanged: user.setPasswordChanged
  }), shallow);
  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const password_mutation = useMJMutation(updatePassword, {
    onSuccess: () => setPasswordChanged(),
    success_toast: {
      body: 'Mot de passe modifié avec succès'
    }
  });

  async function changePassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (new_password.length < 6) {
      return triggerErrorToast('profile.password_too_short');
    }

    if (new_password !== confirm_password) {
      return triggerErrorToast('profile.password_no_match');
    }
    return password_mutation.mutate({ email, password: new_password } as any);
  }

  if (has_changed_password === false) {
    return <Modal title='1ère connexion - Définir un mot de passe'>
      <p className="mt-0 mb-4 text-secondary font-medium">C'est votre première connexion ! Le mot de passe que l'on vous a généré n'est pas forcément très pratique, il est temps de définir le votre 😉</p>
      <form className='' onSubmit={changePassword}>
        <Input
          no_form
          name="password"
          type="password"
          label='Entrer votre mot de passe'
          input={{
            value: new_password,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)
          }} />
        <Input
          no_form
          name="confirm-password"
          type="password"
          label='Confirmer votre mot de passe'
          input={{
            value: confirm_password,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)
          }} />
        <Button align='center' size='medium' type='submit' text='Mettre à jour' />
      </form>
    </Modal>;
  }
  return false;
};
