import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faPowerOff } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import moment from 'moment'
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import config from 'config';
import Button from 'components/widgets/button/button';
import jack from 'components/widgets/header/jack.png';
import Can from 'components/widgets/can';
import { useRocketChatStore } from 'components/hooks/stores/rocketchat';
import { useUserStore } from 'components/hooks/stores/user';
import { useOrganisationStore } from 'components/hooks/stores/organisation';

import packageInfo from '../../../../package.json';
import { generateMenu } from './menu';
import SidenavEntry from './sidenav-entry';
import { SidenavState } from '../navigation';
import shortLogo from './short-logo.svg';
import logo from './logo.svg';
import styles from './sidenav.module.css';

type SidenavProps = {
  disconnect: () => void,
  state: SidenavState,
  dispatch: React.Dispatch<any>
};

const Sidenav = ({ disconnect, state, dispatch }: SidenavProps) => {
  const navigate = useNavigate();
  const user = useUserStore();
  const organisation = useOrganisationStore();
  const location = useLocation();
  const { expand, mobile_visible } = state;
  const is_trial = moment(organisation.trial_start).diff(moment(), 'days') <= config.trial_duration;
  const prefix = user.type === 'pro' ? '' : `/${user.type}`
  const menu = generateMenu(user, organisation);
  const has_notifications = useRocketChatStore(state => state.has_notifications);
  const user_avatar = user.type === 'jack' ? user.avatar_url : encodeURI(organisation.logo_url)

  function getInboxEntryIdx() {
    return menu.findIndex(entry => entry.path === 'inbox');
  }
  const inbox_entry_idx = getInboxEntryIdx();

  // Notifications spot on sidenav
  if (inbox_entry_idx !== -1) {
    menu[inbox_entry_idx].has_notifications = has_notifications;
  }

  return <Translate>
    {({ translate }: { translate: TranslateFunction }) => {
      const logout = {
        key: 42,
        cypress_id: 'header-logout',
        name: 'header.logout',
        icon: faPowerOff,
        onClick: () => {
          const confirm = window.confirm(translate(`profile.confirm_logout`) as string);

          if (confirm) {
            disconnect();
            window.RocketChat(function (this: typeof window.RocketChat) {
              this.registerGuest(null);
              this.minimizeWidget();
            });
          }
        }
      };

      return <React.Fragment>
        <div
          className={classNames({
            [styles['sidenav-background']]: true,
            [styles['sidenav-background-visible']]: mobile_visible
          })}
          onClick={() => dispatch({ type: 'HIDE_MOBILE' })}></div>
        <aside className={classNames({
          [styles['aside']]: true,
          [styles['aside-staging']]: import.meta.env.MODE && import.meta.env.MODE === 'integration',
          [styles['aside-mobile-visible']]: mobile_visible
        })}>
          <div className={styles['aside-content']}>
            <section>
              <div className={classNames({
                [styles['toggle-sticker']]: true,
                [styles['toggle-sticker-expand']]: expand,
                [styles['toggle-sticker-staging']]: import.meta.env.MODE && import.meta.env.MODE === 'integration',
              })} onClick={() => dispatch({ type: 'TOGGLE_SIDENAV' })}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              <div className={classNames({
                [styles['logo-wrapper']]: true,
                [styles['logo-wrapper-expand']]: expand,
                [styles['logo-wrapper-shrink']]: !expand
              })}>
                <Link to='/'>
                  <img src={shortLogo} className={classNames({
                    [styles['logo-expand-short']]: !expand,
                    [styles['logo-shrink']]: expand
                  })} alt='logo' />
                  <img src={logo} className={classNames({
                    [styles['logo-expand-big']]: expand,
                    [styles['logo-shrink']]: !expand
                  })} alt='logo' />
                </Link>
                <button className={styles['close-aside']} onClick={() => dispatch({ type: 'HIDE_MOBILE' })}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </div>
              <div className={styles['metadata']}>
                <div className={classNames({
                  [styles['profile-wrapper']]: true,
                  [styles['profile-wrapper-expand']]: expand,
                  [styles['profile-wrapper-shrink']]: !expand
                })}>
                  <div className={styles['organisation-picture']} style={{ backgroundImage: `url(${user_avatar || jack})` }}></div>
                  <div className={classNames({
                    [styles['username']]: true,
                    [styles['username-expand']]: expand,
                    [styles['username-shrink']]: !expand
                  })}>Hello {user.first_name}&nbsp;!</div>
                </div>
                {organisation.trial_start && is_trial
                  &&
                  <Can
                    perform="header:trial"
                    yes={() => <div className={classNames({
                      [styles['organisation-trial-expand']]: expand,
                      [styles['organisation-trial-shrink']]: !expand,
                    })}>
                      <span className={styles['trial']}>{translate('header.trial') as any}</span>
                    </div>} />
                }
                <Can
                  perform="panel:version"
                  yes={() => <div className={classNames({
                    [styles['version-expand']]: expand,
                    [styles['version-shrink']]: !expand,
                  })}>
                    <span className={styles['version']}>Version {packageInfo.version}</span>
                  </div>} />
              </div>
            </section>
            <section className={styles['menu-wrapper']}>
              <nav>
                {expand && <h3 className={styles['menu-title']}>Menu</h3>}
                <ul className={styles['menu']}>
                  {menu.map(entry => (
                    <SidenavEntry
                      {...entry}
                      prefix={prefix}
                      translate={translate}
                      expand={expand}
                      location={location}
                      dispatch={dispatch} />
                  ))}
                </ul>
              </nav>
              <div className={styles['disconnect']}>
                <SidenavEntry
                  {...logout}
                  prefix={prefix}
                  translate={translate}
                  expand={expand}
                  location={location} />
              </div>
            </section>
            {user.type !== 'jack' && user.role !== 'restricted'
              &&
              <div className={styles['order-jack']}>
                {expand
                  ?
                  <Button
                    size='longer'
                    align='center'
                    onClick={() => navigate(`${prefix}/order`)}
                    text='Commander un Jack' />
                  :
                  <Tooltip placement='right' overlay='Commander un Jack'>
                    { /* Need wrapper to make tooltip work with Button */}
                    <div>
                      <Button
                        shape='round'
                        size='medium'
                        align='center'
                        onClick={() => navigate(`${prefix}/order`)}
                        icon={faPlus} />
                    </div>
                  </Tooltip>
                }
              </div>
            }
            {user.role === 'restricted' && user.type !== 'jack'
              &&
              <div className={styles['order-jack']}>
                {expand
                  ?
                  <Link to='/drafts'>
                    <Button
                      size='longer'
                      align='center'
                      text='Créer un nouveau besoin' />
                  </Link>
                  :
                  <Tooltip placement='right' overlay='Créer un nouveau besoin'>
                    { /* Need wrapper to make tooltip work with Button */}
                    <div>
                      <Link to='/drafts'>
                        <Button
                          shape='round'
                          size='medium'
                          align='center'
                          icon={faPlus} />
                      </Link>
                    </div>
                  </Tooltip>
                }
              </div>
            }
          </div>
        </aside>
      </React.Fragment>
    }}
  </Translate>
};

export default Sidenav;
