import { request } from 'api/request';
import { isArray } from 'lodash';
import { User } from 'types/user';
import { edit_organisation_keys, OrganisationFields } from 'types/organisation';

type ListOrganisationsProps = {
  pageParam: number,
  limit: number,
  account_id?: number,
  sales_id?: number,
  type?: string,
  search?: string,
  with_contracts?: boolean
}

export function listOrganisations(
  { pageParam = 0, limit = 20, account_id, sales_id, type, search, with_contracts }: ListOrganisationsProps
) {
  let query = [`page=${pageParam}`, `limit=${limit}`];

  if (account_id) {
    query.push(`account_id=${account_id}`);
  }

  if (sales_id) {
    query.push(`sales_id=${sales_id}`);
  }

  if (type) {
    query.push(`type=${type}`);
  }

  if (search && search.length > 0) {
    query.push(`query=${search}`);
  }

  if (with_contracts) {
    query.push(`with_contracts=${with_contracts}`);
  }

  return request({
    api_version: '2',
    path: `/organisations${query.length > 0 ? `?${query.join('&')}` : ''}`
  });
}

export function getOrganisation(organisation_id: number) {
  return request({ path: `/organisations/${organisation_id}` });
}

export function editOrganisation(
  { organisation_id, updated_organisation }:
  { organisation_id: number, updated_organisation: Partial<OrganisationFields> }
) {
  const organisation_form = new FormData();

  for (const [key, value] of Object.entries(updated_organisation)) {
    if (edit_organisation_keys.includes(key)) {
      if (key === 'logo' && isArray(value) && value.length > 0) {
        organisation_form.append(key, value[0]);
      }
      else if (key === 'billing_emails' && value && isArray(value) && value.length > 0) {
        value.forEach(email => organisation_form.append('billing_emails[]', email));
      }
      else if (value !== null) {
        organisation_form.append(key, value as string);
      }
    }
  }

  return request({
    path: `/organisations/${organisation_id}`,
    method: 'PUT',
    body: organisation_form
  });
}

export function editOrganisationSubscription(
  { organisation_id, updated_organisation_subscription }:
  { organisation_id: number, updated_organisation_subscription: any }
) {
  return request({
    path: `/organisations/${organisation_id}/subscription`,
    method: 'PUT',
    body: updated_organisation_subscription,
  });
}

export function createOrganisation(new_organisation: Partial<OrganisationFields>) {
  const organisation_form = new FormData();

  for (const [key, value] of Object.entries(new_organisation)) {
    if (key === 'logo' && isArray(value) && value.length > 0) {
      organisation_form.append(key, value[0]);
    }
    else if (key === 'billing_emails' && value && isArray(value) && value.length > 0) {
      value.forEach(email => organisation_form.append('billing_emails[]', email));
    }
    else {
      organisation_form.append(key, value as string);
    }
  }

  return request({
    api_version: '2',
    path: `/organisations`,
    method: 'POST',
    body: organisation_form,
  });
}

export function createChildOrganisation(
  { organisation_id, child_organisation }:
  { organisation_id: number, child_organisation: Partial<OrganisationFields> }
) {
  return request({
    path: `/organisations/${organisation_id}/child`,
    method: 'POST',
    body: child_organisation
  });
}

export function createJackOrganisation(new_organisation: Partial<OrganisationFields>) {
  const organisation_form = new FormData();

  for (const [key, value] of Object.entries(new_organisation)) {
    if (key === 'logo' && isArray(value) && value.length > 0) {
      organisation_form.append(key, value[0]);
    } else {
      organisation_form.append(key, value as string);
    }
  }

  return request({
    path: `/organisations/enterprise`,
    method: 'POST',
    body: organisation_form,
  });
}

export function assignAccountToOrganisation(
  { organisation_id, account_id }:
  { organisation_id: number, account_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/account/${account_id}/assign`,
    method: 'PUT',
  });
}

export function unassignAccountFromOrganisation(
  { organisation_id, account_id }:
  { organisation_id: number, account_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/account/${account_id}/unassign`,
    method: 'PUT',
  });
}

export function assignSalesToOrganisation(
  { organisation_id, sales_id }:
  { organisation_id: number, sales_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/sales/${sales_id}/assign`,
    method: 'PUT',
  });
}

export function unassignSalesFromOrganisation(
  { organisation_id, sales_id }:
  { organisation_id: number, sales_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/sales/${sales_id}/unassign`,
    method: 'PUT',
  });
}

export function fetchUsers(organisation_id: number) {
  return request({ path: `/organisations/${organisation_id}/users` });
};

type ListAddressProps = {
  organisation_id?: number,
  pageParam: number,
  limit: number,
  search?: string,
}

export function fetchOrganisationAddressesPaginated(
  { organisation_id, pageParam = 0, limit = 20, search }: ListAddressProps
) {
  let query = [`page=${pageParam}`, `limit=${limit}`];

  if (search && search.length > 0) {
    query.push(`query=${search}`);
  }

  return request({
    api_version: '2',
    path: `/organisations/${organisation_id}/addresses${query.length > 0 ? `?${query.join('&')}` : ''}`
  });
}


export function createUser(new_user: Partial<User>) {
  return request({
    path: '/organisations/users',
    method: 'POST',
    body: new_user
  });
}

export function deleteUser({ user_id }: { user_id: number }) {
  return request({
    path: `/organisations/users/${user_id}`,
    method: 'DELETE'
  });
};

export function fetchDraftsByAddress(organisation_id: number) {
  return request({ path: `/organisations/${organisation_id}/drafts-by-address` });
};

export function termsAction(
  { organisation_id, status }:
  { organisation_id: number, status: string }
) {
  return request({
    path: `/organisations/${organisation_id}/terms/${status}`,
    method: 'PUT'
  });
};

export function fetchInvoices(organisation_id: number, page = 1, limit = 1000) {
 return request({
    path: `/organisations/${organisation_id}/invoices?page=${page}&limit=${limit}`
  });
};

export function fetchProducts(organisation_id: number, address_id?: number) {
  let path = `/organisations/${organisation_id}/products`;

  if (address_id) {
    path = `${path}?address_id=${address_id}`;
  }
  return request({ path });
};

export function startOrganisationTrial({ organisation_id }: { organisation_id: number }) {
  return request({
    path: `/organisations/${organisation_id}/start-trial`,
    method: 'PUT'
  });
}

export function verifyOrganisation({ organisation_id }: { organisation_id: number }) {
  return request({
    path: `/organisations/${organisation_id}/verify`,
    method: 'PUT',
  });
}

export function deleteOrganisation({ organisation_id }: { organisation_id: number }) {
  request({
    path: `/organisations/${organisation_id}`,
    method: 'DELETE',
  });
};

export function getOrganisationCalendarURL({ orga_id }: { orga_id: number }) {
  return request({ path: `/organisations/${orga_id}/calendar/url` });
};

export function fetchDocuments(organisation_id: number, address_id?: number) {
  const query = [];

  if (address_id) {
    query.push(`address_id=${address_id}`);
  }

  return request({
    path: `/organisations/${organisation_id}/documents${query.length > 0 ? `?${query.join('&')}` : ''}`,
  });
};

export function updateDocument(
  { organisation_id, document_id, name, address_id }:
  { organisation_id: number, document_id: number, name: string, address_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/documents/${document_id}`,
    method: 'PUT',
    body: {
      name,
      address_id
    }
  });
};

export function deleteDocument(
  { organisation_id, document_id }:
  { organisation_id: number, document_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/documents/${document_id}`,
    method: 'DELETE'
  });
};

export function uploadDocument(
  { organisation_id, document, onUploadProgress }:
  { organisation_id: number, document: { name: string, address_id: number, document: File }, onUploadProgress: any }
) {
  const document_form = new FormData();

  for (const [key, value] of Object.entries(document)) {
    if (value) {
      document_form.append(key, value as string);
    }
  }

  return request({
    path: `/organisations/${organisation_id}/documents`,
    method: 'POST',
    body: document_form,
    onUploadProgress
  });
};

export function banJack(
  { organisation_id, jack_id }:
  { organisation_id: number, jack_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/ban/${jack_id}`,
    method: 'PUT'
  });
}

export function unbanJack(
  { organisation_id, jack_id }:
  { organisation_id: number, jack_id: number }
) {
  return request({
    path: `/organisations/${organisation_id}/unban/${jack_id}`,
    method: 'PUT'
  });
}

export function updateOrganisationCanOrder(
  { organisation_id, ban }:
  { organisation_id: number, ban: boolean }
) {
  if (ban) {
    return request({
      path: `/organisations/${organisation_id}/ban`,
      method: 'PUT'
    });
  }
  return request({
    path: `/organisations/${organisation_id}/unban`,
    method: 'PUT'
  });
}

export function getJackBlacklist(organisation_id: number) {
  return request({ path: `/organisations/${organisation_id}/blacklist` });
}

export function getClientSecret(organisation_id: number) {
  return request({
    path: `/organisations/${organisation_id}/payments`,
    method: 'POST',
    body: {
      mode: 'web_embed'
    }
  }).then((r) => {
    console.log(r);
    return r.clientSecret;
  })
}

export function getCheckoutInfos(organisation_id: number) {
  return request({
    path: `/organisations/${organisation_id}/payments`,
    method: 'POST',
    body: {
      mode: 'web'
    }
  }).then((r) => {
    console.log(r);
    return r
  })
}