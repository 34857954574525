import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import shallow from 'zustand/shallow';

import Terms from 'components/widgets/terms/modal';
import ErrorModal from 'components/widgets/can-order/error-modal';
import { useOrganisationStore } from 'components/hooks/stores/organisation';
import { useUserStore } from './stores/user';
import Button from 'components/widgets/button/button';
import Mailto from 'components/widgets/mailto';
import { getTermsLastUpdate } from 'api/firebase'; 
import { get } from 'lodash';

export default function useCanOrder(ordering: boolean = false) {
  const navigate = useNavigate();
  const location = useLocation();
  const [terms_declined, setTermsDeclined] = React.useState(false);


  const { verification_status, terms_status, has_valid_payment_method, updateHasToAcceptTerms, terms_accepted_at, has_to_accept_terms } = useOrganisationStore(orga => ({
    verification_status: orga.verification_status,
    terms_status: orga.terms_status,
    has_valid_payment_method: orga.has_valid_payment_method,
    updateHasToAcceptTerms: orga.updateHasToAcceptTerms,
    terms_accepted_at: orga.terms_accepted_at,
    has_to_accept_terms: orga.has_to_accept_terms,
  }), shallow);
  const { user_type, user_role, can_order, organisation_id, updateCanOrder } = useUserStore(user => ({
    user_type: user.type,
    user_role: user.role,
    can_order: user.can_order,
    organisation_id: user.fk_organisation_id,
    updateCanOrder: user.updateCanOrder,
  }), shallow);

  // console.log(verification_status, terms_status, user_type, user_role, can_order, organisation_id, terms_declined, has_valid_payment_method);

  React.useEffect(() => {
    async function getT() {
      console.log('querying firebase');
      const last_updated_timestamp = await getTermsLastUpdate();
      const last_update = new Date(last_updated_timestamp);

      // never accepted ?
      const t = new Date(terms_accepted_at || 0);
      const diff = t.getTime() - last_update.getTime();

      if (diff < 0) {
        console.log('terms have been updated');
        updateHasToAcceptTerms(true);
        updateCanOrder(false);
      } else {
        console.log('terms have not been updated');
        updateHasToAcceptTerms(false);
      }
    };
    getT();
  }, [updateHasToAcceptTerms, terms_accepted_at, updateCanOrder]);

  // console.log('has_to_accept', has_to_accept_terms);

  if (user_type === 'pro') {
    if (verification_status === 'pending' || verification_status === 'unverified') {
      return <ErrorModal title='terms.pending_validation' message='errors.not_verified' />;
    }

    if (can_order === false) {
      if (terms_declined) {
        return <ErrorModal title='terms.title' message='errors.terms_refused' />;
      }
      else if (['admin', 'manager'].includes(user_role) && ['never-accepted', 'acceptation-needed', 'refused'].includes(terms_status)) {
        return <Terms onTermUpdate={setTermsDeclined} />;
      }
      else if (user_role === 'user' && ['never-accepted', 'acceptation-needed'].includes(terms_status)) {
        return <ErrorModal title='terms.title' message='errors.pending_terms_acceptation' />;
      }
      else if (terms_status === 'refused') {
        return <ErrorModal title='terms.title' message='errors.terms_refused' />;
      }
      else if (location.pathname.includes('organisations')) {
        return false;
      }
      else if (!has_valid_payment_method) {
        if (location.pathname.includes('drafts') && !ordering) {
          return false;
        }
        return <ErrorModal
          title='errors.no_payment_title'
          message='errors.no_payment_method'
          action={<Button
            align='center'
            color="primary"
            variant="reversed"
            size="longer"
            text="Ajouter un moyen de paiement"
            onClick={() => navigate(`/organisations/${organisation_id}?view=payment_methods`)} />} />;
      }
      else if (has_to_accept_terms) {
        if (location.pathname.includes('drafts') && !ordering) {
          return false;
        }
        return <Terms onTermUpdate={setTermsDeclined} />;;
      }

      return <ErrorModal
        title='terms.not_allowed'
        message='errors.suspended'
        infos={<Translate
          id="errors.contact"
          data={{
            mail_compta: <Mailto mail='invoice@mercijack.co' />,
            mail_ops: <Mailto mail='ops@mercijack.co' />
          }} />}
        action={<Button
          align='center'
          color="primary"
          variant="reversed"
          size="longer"
          text="Consulter mes factures"
          onClick={() => navigate(`/organisations/${organisation_id}?view=invoices`)} />} />;
    }
  }
  return false;
};
