import create from 'zustand';
import sha1 from 'crypto-js/sha1';

import { OfflineStore } from 'types/store';
import config from 'config';

export const useOfflineStore = create<OfflineStore>(set => ({
  syncing: false,
  requests_set: new Set<string>(),
  requests_queue: {},
  queue_size: 0,
  addRequest: (request, priority, refresh_key?) => {
    set(state => {
      const request_to_format = { ...request };

      if (request_to_format.data instanceof FormData) {
        const data: any = {};

        for (const [key, value] of request_to_format.data.entries()) {
          data[key] = value;
        }
        request_to_format.data = data;
      }
      const request_sha1 = sha1(JSON.stringify(request_to_format)).toString();

      if (!state.requests_set.has(request_sha1)) {
        const requests_queue = { ...state.requests_queue };

        state.requests_set.add(request_sha1);
        if (!(priority in requests_queue)) {
          requests_queue[priority] = [];
        }
        requests_queue[priority].push({
          request,
          retries: 0,
          max_retry: config.offline.max_retries,
          refresh_key,
          priority,
          synced: false
        });
        return {
          ...state,
          requests_queue,
          queue_size: state.queue_size + 1
        };
      }
      return state;
    });
  },
  emptyQueue: () => set(() => ({
    requests_set: new Set(),
    requests_queue: {},
  })),
  updateQueue: (requests_queue, requests_set) => set(() => ({
    requests_set,
    requests_queue
  })),
  setSyncing: syncing => set(() => ({ syncing })),
  decrementQueueSize: () => set(state => ({ queue_size: state.queue_size - 1 }))
}));
