import { toast } from 'react-toastify';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { useNetworkStore } from 'components/hooks/stores/network';

type ErrorToastProps = {
  error: any
};

const ErrorToast = ({ error }: ErrorToastProps) => <div className="font-medium"><Translate id={error} /></div>;

export function triggerErrorToast(error: string | string[]) {
  const selected_error = Array.isArray(error) ? error[0] : error;
  const render = selected_error.search(/\./g) !== -1 ? <ErrorToast error={selected_error} /> : selected_error;
  const pending_toast = useNetworkStore.getState().pending_toast;

  if (pending_toast) {
    return toast.update(pending_toast, {
      type: 'error',
      icon: <FontAwesomeIcon icon={faTimes} color='#e74c3c' />,
      render,
      autoClose: 3000,
      hideProgressBar: false
    });
  }
  return useNetworkStore.getState().setPendingToast(toast.error(render, {
    icon: <FontAwesomeIcon icon={faTimes} color='#e74c3c' />,
    onClose: () => useNetworkStore.getState().clearPendingToast()
  }));
}
