import { GlobalConfig, EnvironmentConfig, activity_areas, sub_activity_areas, location_zones } from './types/config';

type AppEnvironement = keyof GlobalConfig;

const integ_ops = [{
  label: 'Tous',
  value: 0
}, {
  label: 'Aucun',
  value: -1
}, {
  value: 99,
  label: 'Quimoune'
}, {
  value: 249,
  label: 'Raph'
}, {
  value: 1,
  label: 'Will'
}];

const environment: AppEnvironement = import.meta.env.MODE as AppEnvironement || 'development';
const default_values: Pick<EnvironmentConfig,
'can_order_refresh_delay' | 'terms_url' | 'jack_terms_url' | 'faq_url' | 'electronic_signature_threshold' | 'prevent_offer_id' |
'trial_duration' | 'stats_default_address' | 'stats_default_orga' | 'ops' | 'accounts' | 'sales' |
'activity_areas' | 'sub_activity_areas' | 'firebase' | 'history_stats_organisations_blacklist' | 'offline'
|'cartography' | 'zones' | 'android' | 'stripe' | 'sinao'> = {
  can_order_refresh_delay: 300,
  terms_url: 'https://storage.googleapis.com/merci-jack/misc/cgu.pdf',
  jack_terms_url: 'https://www.google.fr',
  faq_url: 'https://www.notion.so/FAQ-aa4186b6c95d4d20bced569bad7427ce',
  electronic_signature_threshold: 150,
  ops: [{
    label: 'Tous',
    value: 0
  }, {
    label: 'Aucun',
    value: -1
  }, {
    value: 6275,
    label: 'Richard',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6275-1594654916950.jpeg'
  }, {
    value: 6193,
    label: 'Victor',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6193-1594654965167.jpeg'
  }, {
    value: 7060,
    label: 'Margaux',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/7060.jpg'
  }, {
    value: 7830,
    label: 'Romuald',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/7830.jpg'
  }, {
    value: 7194,
    label: 'Pierre',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/pierre-theillier.jpg'
  }, {
    value: 6256,
    label: 'Manu',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6256-1594654931240.jpeg'
  }, {
    value: 8014,
    label: 'Myriam',
  }],
  accounts: [{
    label: 'Tous',
    value: 0
  }, {
    label: 'Aucun',
    value: -1
  }, {
    value: 6346,
    label: 'Jéjé',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6346-1594654859632.jpeg'
  }, {
    value: 6256,
    label: 'Manu',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6256-1594654931240.jpeg'
  }, {
    value: 6193,
    label: 'Victor',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6193-1594654965167.jpeg'
  }, {
    value: 8212,
    label: 'Hugo'
  }, {
    value: 8468,
    label: 'Alexandre'
  }],
  sales: [{
    label: 'Tous',
    value: 0
  }, {
    label: 'Aucun',
    value: -1
  }, {
    value: 6367,
    label: 'Laure',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6367-1601569030475.jpeg'
  },  {
    value: 6712,
    label: 'Guillaume',
    logo: 'https://storage.googleapis.com/merci-jack-production/images/avatar/6712.jpg'
  }, {
    value: 7963,
    label: 'Younes',
  }, {
    value: 7972,
    label: 'Chawki'
  }],
  prevent_offer_id: 10,
  trial_duration: 60,
  stats_default_orga: 'b626ea4e-ec4a-4e9a-9d9a-7d6cbeb3fae0', // Deskeo
  stats_default_address: '2268', // 'Gouvion 9
  activity_areas,
  sub_activity_areas,
  firebase: {
    global: {
      apiKey: "AIzaSyCheE2FPQkREOi_mTtgV1eoDB0tH_BxI4A",
      authDomain: "mj-integ.firebaseapp.com",
      databaseURL: "https://mj-integ.firebaseio.com",
      projectId: "mj-integ",
      storageBucket: "mj-integ.appspot.com",
      messagingSenderId: "962902056608",
      appId: "1:962902056608:web:64e09554ce150f7a9cb3c5",
      measurementId: "G-5D1379XZQJ"
    },
    push: {
      vapidKey: 'BEr17nL1EjCBWoeXom8COi9pSU2q3Rxh-mH9RiDw-wg0gcPbcXnrem7_2wVd2XgIkXB9XOnHHE3OLEj1QYHszhE'
    }
  },
  history_stats_organisations_blacklist: [],
  offline: {
    sync_timeout: 30000,
    default_priority: 42,
    max_retries: 2
  },
  cartography: {
    state_step_usage_master_works: [23, 26, 29],
    degraded_operation_step_master_works: [17, 23, 26, 29]
  },
  zones: location_zones,
  android: {
    playstore_url: 'https://play.google.com/store/apps/details?id=co.mercijack.app.twa',
    init_url: 'https://storage.googleapis.com/merci-jack-android/init.json'
  },
  stripe: {
    publishable_key: 'pk_test_Vr9Y3sZPSeULrBisKqgCPgYO',
    jack_account_url: 'https://dashboard.stripe.com/test/connect/accounts',
    payment_intent_url: 'https://dashboard.stripe.com/test/payments'
  },
  sinao: {
    account_name: 'mercijack'
  }
};

const config: GlobalConfig = {
  development: {
    ...default_values,
    api: {
      protocol: 'http',
      url: 'localhost:4242',
    },
    rocket_chat_api: {
      baseURL: 'chat-integration.mercijack.co',
    },
    mix_panel: {
      token: '45242cb7d69ac2230821d3e579da5871',
      server_url: 'https://api-eu.mixpanel.com',
    },
    algolia: {
      zone_index: 'remote_ZONES',
      masterwork_index: 'staging_MASTERWORKS',
      jack_index: 'staging_JACKS',
      jack_enterprise_index: 'staging_JACK_ENTERPRISE',
      material_index: 'staging_MATERIALS',
      apparatus_index: 'remote_APPARATUS',
      application_id: '22Y0Z11MF2',
      search_api_key: '2316c5e11b46217d7cdd12d2329fd693',
      organisation_index: 'staging_ORGANISATIONS',
      solutions_index: 'staging_SOLUTIONS',
      manufacturer_index: 'staging_MANUFACTURERS'
    },
    ops: integ_ops,
    accounts: integ_ops,
    sales: integ_ops
  },
  remote: {
    ...default_values,
    api: {
      protocol: 'https',
      url: 'api-staging-dot-mj-2016.appspot.com',
    },
    rocket_chat_api: {
      baseURL: 'chat-integration.mercijack.co',
    },
    mix_panel: {
      token: '45242cb7d69ac2230821d3e579da5871',
      server_url: 'https://api-eu.mixpanel.com',
    },
    algolia: {
      zone_index: 'remote_ZONES',
      masterwork_index: 'staging_MASTERWORKS',
      jack_index: 'staging_JACKS',
      jack_enterprise_index: 'staging_JACK_ENTERPRISE',
      material_index: 'staging_MATERIALS',
      apparatus_index: 'remote_APPARATUS',
      application_id: '22Y0Z11MF2',
      search_api_key: '2316c5e11b46217d7cdd12d2329fd693',
      organisation_index: 'staging_ORGANISATIONS',
      solutions_index: 'staging_SOLUTIONS',
      manufacturer_index: 'staging_MANUFACTURERS'
    },
    ops: integ_ops,
    accounts: integ_ops,
    sales: integ_ops
  },
  integration: {
    ...default_values,
    api: {
      protocol: 'https',
      url: 'api-staging-dot-mj-2016.appspot.com',
    },
    rocket_chat_api: {
      baseURL: 'chat-integration.mercijack.co',
    },
    mix_panel: {
      token: '45242cb7d69ac2230821d3e579da5871',
      server_url: 'https://api-eu.mixpanel.com',
    },
    algolia: {
      zone_index: 'staging_ZONES',
      masterwork_index: 'staging_MASTERWORKS',
      jack_index: 'staging_JACKS',
      jack_enterprise_index: 'staging_JACK_ENTERPRISE',
      material_index: 'staging_MATERIALS',
      apparatus_index: 'staging_APPARATUS',
      application_id: '22Y0Z11MF2',
      search_api_key: '2316c5e11b46217d7cdd12d2329fd693',
      organisation_index: 'staging_ORGANISATIONS',
      solutions_index: 'staging_SOLUTIONS',
      manufacturer_index: 'staging_MANUFACTURERS'
    },
    ops: integ_ops,
    accounts: integ_ops,
    sales: integ_ops
  },
  production: {
    ...default_values,
    api: {
      protocol: 'https',
      url: 'api.mercijack.co',
    },
    rocket_chat_api: {
      baseURL: 'chat.mercijack.co',
    },
    mix_panel: {
      token: '6c2f3ad778f94d73ec5e8d2f47485b86',
      server_url: 'https://api-eu.mixpanel.com',
    },
    algolia: {
      zone_index: 'production_ZONES',
      masterwork_index: 'production_MASTERWORKS',
      jack_index: 'production_JACKS',
      jack_enterprise_index: 'production_JACK_ENTERPRISE',
      material_index: 'production_MATERIALS',
      apparatus_index: 'production_APPARATUS',
      application_id: '22Y0Z11MF2',
      search_api_key: '2316c5e11b46217d7cdd12d2329fd693',
      organisation_index: 'production_ORGANISATIONS',
      solutions_index: 'production_SOLUTIONS',
      manufacturer_index: 'production_MANUFACTURERS'
    },
    firebase: {
      global: {
        apiKey: 'AIzaSyDhUkbr_7ZLjRu4HxBpQcaQ9q7fUI997b8',
        authDomain: 'mj-2016.firebaseapp.com',
        databaseURL: 'https://mj-2016.firebaseio.com',
        projectId: 'mj-2016',
        storageBucket: 'mj-2016.appspot.com',
        messagingSenderId: '1020400434810',
        appId: '1:1020400434810:web:4e7b22b34631be8a55c5c3',
        measurementId: 'G-MZ0LBCBEN2'
      },
      push: {
        vapidKey: 'BBnPA6cVv21AW0yWebHElk9SwdCRUmLmKDoYrxyZUQ7trhVk0shOWs2TJiPkhloku0nQemtHf3ssY9VoJzR8n1c'
      }
    },
    history_stats_organisations_blacklist: [1, 744, 806],
    stripe: {
      publishable_key: 'pk_live_g0NhkNhkRc2RjSc6vxyzHRbo',
      jack_account_url: 'https://dashboard.stripe.com/connect/accounts',
      payment_intent_url: 'https://dashboard.stripe.com/payments'
    },
    sinao: {
      account_name: 'mercijack1'
    }
  },
  demo: {
    ...default_values,
    api: {
      protocol: 'https',
      url: 'api-demo-dot-mj-2016.appspot.com',
    },
    rocket_chat_api: {
      baseURL: 'chat-integration.mercijack.co',
    },
    mix_panel: {
      token: '45242cb7d69ac2230821d3e579da5871',
      server_url: 'https://api-eu.mixpanel.com',
    },
    algolia: {
      zone_index: 'demo_ZONES',
      masterwork_index: 'demo_MASTERWORKS',
      jack_index: 'demo_JACKS',
      jack_enterprise_index: 'demo_JACK_ENTERPRISE',
      material_index: 'production_MATERIALS',
      apparatus_index: 'demo_APPARATUS',
      application_id: '22Y0Z11MF2',
      search_api_key: '2316c5e11b46217d7cdd12d2329fd693',
      organisation_index: 'demo_ORGANISATIONS',
      solutions_index: 'production_SOLUTIONS',
      manufacturer_index: 'production_MANUFACTURERS'
    }
  }
};

console.log('environnment is', environment);
console.log('config is', config[environment]);

export default config[environment];
