import create from 'zustand';

import { NetworkStore } from 'types/store';

export const useNetworkStore = create<NetworkStore>(set => ({
  fetching_toast: undefined,
  pending_toast: undefined,
  setFetchingToast: fetching_toast => set({ fetching_toast }),
  setPendingToast: pending_toast => set({ pending_toast }),
  clearFetchingToast: () => set({ fetching_toast: undefined }),
  clearPendingToast: () => set({ pending_toast: undefined }),
}));
