import React from 'react';
import keyBy from 'lodash/keyBy';

import { useMJQuery } from 'components/hooks/api/query';
import { getAllGroups, getInterventionGroup, getLivechatMessages, getLiveChatRooms, getLivechatUserInfo, getMyGroups, getUserInfo, rocketChatLogin, setStatus } from 'api/rocketchat';
import { RocketGroup, RocketGroupMessage, RocketLoginUser } from 'types/rocket-chat';
import { GroupsMetadata } from 'components/routes/inbox/ops-groups-list';
import { UserGroupsMetadata } from 'components/routes/inbox/groups-list';
import { enrichAllGroups, enrichArchivedGroups, enrichMyGroups, insertLiveGroups } from 'components/routes/inbox/utils';
import { useUserStore } from '../stores/user';
import { useRocketChatStore } from '../stores/rocketchat';
import isEmpty from 'lodash/isEmpty';

export const rocketchat_keys = {
  rocketchat: ['rocketchat'] as const,
  login: (user_email: string) => [...rocketchat_keys.rocketchat, 'login', user_email] as const,
  notifications: () => [...rocketchat_keys.rocketchat, 'notifications'] as const,
  groupsListKey: () => [...rocketchat_keys.rocketchat, 'groups_list'] as const,
  interventionGroupKey: () => [...rocketchat_keys.rocketchat, 'intervention_group'] as const,
  groupsList: (is_archived?: boolean) => [...rocketchat_keys.groupsListKey(), is_archived] as const,
  interventionGroup: (intervention_id: number, group_type: 'users' | 'jacks' | 'ops') => [...rocketchat_keys.interventionGroupKey(), intervention_id, group_type] as const,
};

export const livechat_keys = {
  livechat: ['livechat'] as const,
  livechatMessagesKey: () => [...livechat_keys.livechat, 'livechat_messages'] as const,
  livechatInfosKey: () => [...livechat_keys.livechat, 'livechat_infos'] as const,
  livechatMessages: (initial_room?: RocketGroup) => [...livechat_keys.livechatMessagesKey(), initial_room] as const,
  livechatInfos: (initial_room?: RocketGroup) => [...livechat_keys.livechatInfosKey(), initial_room] as const,
};

export const useRocketChatLogin = (user_email: string, enabled?: boolean): any => {
  const login = useRocketChatStore(store => store.login);

  return useMJQuery(rocketchat_keys.login(user_email), async () => {
    const res = await rocketChatLogin(user_email);
    const rc_user: RocketLoginUser = res.data;

    login(rc_user);

    if (res.data && res.data.me && res.data.me.status === 'offline') {
      await setStatus('online');
    }

    return rc_user;
  }, {
    staleTime: 3600000,
    enabled,
  })
};

export const useRocketChatNotifications = (enabled?: boolean): any => {
  const setNotifications = useRocketChatStore(store => store.setNotifications);

  const user_type = useUserStore(user => user.type);

  return useMJQuery(rocketchat_keys.notifications(), async () => {
    let has_notifications = false;
    const promises = [getUserInfo()];

    if (user_type === 'ops') {
      promises.push(getLiveChatRooms());
      promises.push(getAllGroups(false));
    }
    const [user_info_data, livechat_rooms_data, all_groups_data] = await Promise.all(promises);
    const { user: user_infos } = user_info_data;

    for (let i = 0; i < user_infos.rooms.length; i++) {
      if (user_infos.rooms[i].unread > 0) {
        has_notifications = true;
        break;
      }
    }

    if (!has_notifications && livechat_rooms_data && livechat_rooms_data.rooms) {
      for (let i = 0; i < livechat_rooms_data.rooms.length; i++) {
        if (livechat_rooms_data.rooms[i].waitingResponse && isEmpty(livechat_rooms_data.rooms[i].servedBy)) {
          has_notifications = true;
          break;
        }
      }
    }

    if (!has_notifications && all_groups_data && all_groups_data.groups) {
      for (let i = 0; i < all_groups_data.groups.length; i++) {
        if (all_groups_data.groups[i].usersCount < 3) {
          if (!all_groups_data.groups[i].fname.includes('ops')) {
            has_notifications = true;
            break;
          }
        }
      }
    }

    setNotifications(has_notifications);
    return { has_notifications };
  }, {
    staleTime: 30000,
    refetchInterval: 30000,
    refetchOnWindowFocus: true,
    enabled,
  })
};

export const useRocketChatGroup = (intervention_id: number, group_type: 'users' | 'jacks' | 'ops', enabled?: boolean): any => (
  useMJQuery(rocketchat_keys.interventionGroup(intervention_id, group_type), async () => {
    const res = await getInterventionGroup({ intervention_id, group_type });

    return res.group || null;
  }, {
    staleTime: 10000,
    enabled,
    refetchOnWindowFocus: true,
  })
);

export const useRocketChatLivechatMessages = (initial_room?: RocketGroup, enabled?: boolean): any => (
  useMJQuery(livechat_keys.livechatMessages(initial_room), async () => {
    const { messages } : { messages?: RocketGroupMessage[] } = await getLivechatMessages(initial_room!._id!, initial_room!.v?.token!);

    return messages;
  }, {
    staleTime: 10000,
    enabled,
    refetchOnWindowFocus: false,
  })
);

export const useRocketChatLivechatFromVisitorInfo = (initial_room?: RocketGroup, enabled?: boolean): any => (
  useMJQuery(livechat_keys.livechatInfos(initial_room), async () => {
    const { visitor } = await getLivechatUserInfo(initial_room!.v?.token!);

    return visitor;
  }, {
    staleTime: 30000,
    enabled,
    refetchOnWindowFocus: false,
  })
);

export const useOpsRocketChatGroupsList = (is_archived: boolean, setOnce: React.Dispatch<React.SetStateAction<boolean>>, enabled?: boolean): any => {
  const rc_user = useRocketChatStore(store => store.rc_user);

  return useMJQuery(rocketchat_keys.groupsList(is_archived), async () => {
    const { groups: mine = [] }: { groups?: RocketGroup[] } = await getMyGroups();
    const { groups: all = [] }: { groups?: RocketGroup[] } = await getAllGroups(is_archived);
    const { rooms: live = [] }: { rooms?: RocketGroup[] } = await getLiveChatRooms();
    const { user: user_infos } = await getUserInfo();

    if (!is_archived) {
      insertLiveGroups(rc_user!, live || [], mine || [], all || []);
    }

    const grouped_rooms = keyBy(user_infos.rooms, 'rid');
    const { unread_count, my_groups } = enrichMyGroups(mine, grouped_rooms, rc_user!);
    const { no_ops_groups_count, all_groups } = enrichAllGroups(all);

    setOnce(false);

    return { my_groups, unread_count, all_groups, no_ops_groups_count } as GroupsMetadata;
  }, {
    staleTime: 10000,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled,
  })
};

export const useRocketChatGroupsList = (setOnce: React.Dispatch<React.SetStateAction<boolean>>, enabled?: boolean): any => {
  const rc_user = useRocketChatStore(store => store.rc_user);

  return useMJQuery(rocketchat_keys.groupsList(), async () => {
    const { groups: mine = [] }: { groups?: RocketGroup[] } = await getMyGroups();
    const { user: user_infos = {} } = await getUserInfo();

    const grouped_rooms = keyBy(user_infos.rooms, 'rid');
    const { unread_count, my_groups } = enrichMyGroups(mine.filter((group: RocketGroup) => !group.archived), grouped_rooms, rc_user!);
    const { unread_archived_count, archived_groups } = enrichArchivedGroups(mine.filter((group: RocketGroup) => group.archived), grouped_rooms);

    setOnce(false);

    return { my_groups, unread_count, archived_groups, unread_archived_count } as UserGroupsMetadata;
  }, {
    staleTime: 10000,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled,
  })
};
