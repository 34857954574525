import { Workbox } from 'workbox-window';

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onRegistration?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if (import.meta.env.PROD && 'serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');

    wb.addEventListener('activated', (event) => {
      console.log('SW - ACTIVATED');
      if (!event.isUpdate) {
        console.log('Service Worker up and ready for duty sir!')
      }
    });

    wb.addEventListener('waiting', (event) => {
      console.log('SW - WAITING');
      wb.messageSkipWaiting();
    });

    wb.addEventListener('controlling', () => {
      console.log('SW - CONTROLLING');
      window.location.reload();
    });
    wb.register().then((registration) => {
      // Need registration for Firebase push notifications
      if (registration && config && config.onRegistration) {
        config.onRegistration(registration);
      }
    });
  }
}
