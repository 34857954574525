import * as React from 'react';
import shallow from 'zustand/shallow';

import { User } from 'types/user';
import { useRocketChatStore } from 'components/hooks/stores/rocketchat';
import { useOrganisationStore } from 'components/hooks/stores/organisation';
import { useRocketChatLogin, useRocketChatNotifications } from './api/rocketchat';

export default function useRocketChat(is_logged_in: boolean, user: User = {} as User) {
  const { organisation_id, organisation_name, organisation_logo } = useOrganisationStore(orga => ({
    organisation_id: orga.id,
    organisation_name: orga.name,
    organisation_logo: orga.logo_url,
  }), shallow);
  const rc = useRocketChatStore();

  const { data: rc_user } = useRocketChatLogin(user.email, (is_logged_in && !!user.email));

  useRocketChatNotifications(!!rc_user);

  // Initialize widget
  React.useEffect(() => {
    window.RocketChat(function (this: typeof window.RocketChat) {
      this.initialize({
        theme: {
          color: '#112544',
          fontColor: '#FFFFFF',
          iconColor: '#1d74f5',
          title: "Merci Jack",
          offlineTitle: "Actuellement hors service",
        },
        department: 'ops',
        language: 'fr',
      });
    });
  }, []);

  // Setup user info
  React.useEffect(() => {
    function setupRocketChatUser() {
      window.RocketChat(function (this: typeof window.RocketChat) {
        if (user.type === 'ops') {
          this.hideWidget();
          const widget_wrapper = document.getElementsByClassName('rocketchat-widget') as HTMLCollectionOf<HTMLElement>;

          widget_wrapper[0].style.display = 'none';
        }
        else {
          this.showWidget();
          this.registerGuest({
            token: rc.rc_user!.userId,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            customFields: [
              { key:  'user_id', value: user.id.toString() || '', overwrite: true },
              { key:  'user_name', value: `${user.first_name} ${user.last_name}`, overwrite: true },
              { key:  'user_type', value: user.type || '', overwrite: true },
              { key:  'user_phone_number', value: user.phone_number || '', overwrite: true },
              { key:  'user_avatar_url', value: user.avatar_url || '', overwrite: true },
              { key:  'user_email', value: user.email || '', overwrite: true },
            ]
          });
          if (organisation_id && organisation_id.toString() !== '' && organisation_id.toString() !== '0') {
            this.setCustomField('organisation_id', organisation_id?.toString(), true);
            this.setCustomField('organisation_name', organisation_name, true);
            this.setCustomField('organisation_logo', organisation_logo, true);
          }
        }
      });
    }

    if (is_logged_in && rc.rc_user) {
      setupRocketChatUser();
    }
  }, [is_logged_in, user.id, user.email, user.first_name, user.last_name, user.type, user.avatar_url, user.phone_number, rc.rc_user, organisation_id, organisation_name, organisation_logo]);

  // Rocketchat responsive auto hide
  React.useEffect(() => {
    function onResize() {
      window.RocketChat(function (this: typeof window.RocketChat) {
        window.innerWidth < (60 * 16) ? this.hideWidget() : this.showWidget(); // 60em * 16px -> medium breakpoint
      });
    }

    if (is_logged_in && rc.rc_user && user.type !== 'ops') {
      window.addEventListener('resize', onResize);
      onResize();
    }
    return () => window.removeEventListener('resize', onResize);
  }, [is_logged_in, user.type, rc.rc_user]);
}
