import * as React from 'react';
import { useQuery, UseQueryOptions, UseInfiniteQueryOptions, useMutation, UseMutationOptions, useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { useNetworkStore } from 'components/hooks/stores/network';
import { triggerErrorToast } from 'components/widgets/toast/error';
import { triggerSuccessToast, SuccessToastProps } from 'components/widgets/toast/success';

type useMJMutationOptions = {
  error_messages?: { [key: string]: string },
  success_toast?: SuccessToastProps
} & Pick<UseMutationOptions, 'onSuccess' | 'onError' | 'onSettled' | 'onMutate'>;

type MJNetworkError = {
  code: string,
  developerMessage: string | string[];
  path: string;
  statusCode: number;
  timestamp: string;
}

export const useMJQuery = (deps: any, callback: () => any, options?: UseQueryOptions) => {
  const res = useQuery(deps, callback, {
    retry: false,
    ...options,
    onError: (err: any) => {
      if (!err.code || err.code !== 'network_offline') {
        triggerErrorToast(err.developerMessage || err.message || 'errors.server_error');
        // Prevent error toast stacking if multiple queries are triggered at the same time
        toast.clearWaitingQueue();
      }
    }
  });

  return res;
};

export const useMJLazyQuery = (deps: any, callback: ({ pageParam }: { pageParam?: number }) => any, options?: UseInfiniteQueryOptions) => {
  const res = useInfiniteQuery(deps, callback, {
    retry: false,
    getNextPageParam: (lastPage: any) => {
      if (lastPage.info.page === lastPage.info.last_page) {
        return undefined;
      }
      return lastPage.info.page + 1;
    },
    ...options,
    onError: (err: any) => {
      triggerErrorToast(err.developerMessage || err.message || 'errors.server_error');
      // Prevent error toast stacking if multiple queries are triggered at the same time
      toast.clearWaitingQueue();
    }
  });

  return res;
};

export const useMJMutation = (callback: any, options?: useMJMutationOptions) => {
  const defaultOnError = React.useCallback((data: any) => {
    const error = data as MJNetworkError;
    let error_message = 'errors.operation_not_successfull';

    if (options?.error_messages && error.code && error.code in options.error_messages) {
      error_message = options.error_messages[error.code];
    }
    else if (error.developerMessage) {
      if (typeof error.developerMessage === 'string') {
        error_message = error.developerMessage;
      } else if (error.developerMessage.length > 0) {
        error_message = error.developerMessage[0];
      }
    }

    if (!error.code || error.code !== 'network_offline') {
      triggerErrorToast(error_message);
    }
  }, [options]);
  const onSuccess = React.useCallback((data: any, variables: any, context: any) => {
    if (options?.success_toast) {
      triggerSuccessToast(options.success_toast.body, options.success_toast.footer);
    }
    else {
      toast.update(useNetworkStore.getState().pending_toast!, { autoClose: 1000 });
    }

    if (options?.onSuccess) {
      options.onSuccess(data, variables, context);
    }
  }, [options]);

  if (options && !options.onError) {
    options.onError = defaultOnError;
  }

  return useMutation(callback, {
    ...options,
    onSuccess
  });
};
