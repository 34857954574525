import * as React from 'react';
import axios from 'axios';
import Bluebird from 'bluebird';
import sha1 from 'crypto-js/sha1';
import shallow from 'zustand/shallow';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import config from 'config';
import { useOfflineStore } from 'components/hooks/stores/offline';
import { SyncedRequest } from 'types/store';
import { forgeRefreshRequest } from 'api/request';
import { useAppStore } from 'components/hooks/stores/app';

export default function useOfflineSync() {
  const queryClient = useQueryClient();
  const online = useAppStore(app => app.online);
  const {
    requests_queue, updateQueue,
    emptyQueue, setSyncing, decrementQueueSize
  } = useOfflineStore(store => ({
    requests_queue: store.requests_queue,
    emptyQueue: store.emptyQueue,
    setSyncing: store.setSyncing,
    updateQueue: store.updateQueue,
    decrementQueueSize: store.decrementQueueSize
  }), shallow);

  React.useEffect(() => {
    async function syncNetwork() {
      const unsynced_requests: SyncedRequest[] = [];
      const refresh_requests: (string | number)[][] = [];
      const priority_list = Object.keys(requests_queue).sort();
      let access_token = '';

      console.log('ON GENERE UN ACCESS TOKEN NEUF POUR EVITER UNE 401');
      try {
        const { data } = await forgeRefreshRequest();

        access_token = data.credentials.access_token;
      }
      catch (err) {
        // Refresh gets sometimes aborted, dunno why, so we retry
        const { data } = await forgeRefreshRequest();

        access_token = data.credentials.access_token;
      }

      try {
        await Bluebird.mapSeries(priority_list, async (priority, idx) => {
          console.log(`ON TRAITE LES REQUÊTES DE PRIORITÉ ${priority}`);
          await Bluebird.map(requests_queue[priority], async request => {
            try {
              request.request.timeout = config.offline.sync_timeout;
              if (request.request.headers && 'Authorization' in request.request.headers) {
                request.request.headers['Authorization'] = `Bearer ${access_token}`;
              }
              const res = await axios.request(request.request);

              if (request.refresh_key) {
                refresh_requests.push(request.refresh_key);
              }
              request.synced = true;
              decrementQueueSize();
              return res;
            }
            catch (err) {
              console.log('QUELQUE CHOSE A MERDÉ !');
              if (axios.isAxiosError(err)) {
                if (!err.response || err.code === 'ECONNABORTED') {
                  console.log('ON A PERDU LA CONNECTION OU CA A TIMEOUT');
                  return;
                }
              }
              console.log('ON IGNORE L‘ERREUR TANT PIS');
              request.synced = true;
              decrementQueueSize();
            }
          }, { concurrency: 3 });
          console.log(`ON VERIFIE LES REQUÊTES QUI ONT ECHOUÉES QU‘ON DOIT REPUSH EN PRIORITE ${priority}`);
          const unsynced_requests_in_priority = requests_queue[priority].filter(request => !request.synced);

          if (unsynced_requests_in_priority.length > 0) {
            console.log(`IL Y A ${unsynced_requests_in_priority.length} REQUETES A REPUSH`);
            unsynced_requests_in_priority.forEach(request => {
              if (request.retries < request.max_retry) {
                console.log(`ON REPUSH DANS LA QUEUE ${request.request.method} - ${request.request.url} ET ON RETENTE PLUS TARD (${request.max_retry - request.retries} essais restants)`);
                unsynced_requests.push({ ...request, retries: request.retries + 1 });
              }
            });
            console.log('ON REPUSH LE RESTE DES PRIORITES ET ON TERMINE LA BOUCLE DE SYNCHRO');
            for (let i = idx + 1; i < priority_list.length; i++) {
              const priority = priority_list[i];

              unsynced_requests.push(...requests_queue[priority]);
            }
            throw new Error('ECHEC DE LA SYNCHRO, VA FALLOIR RECOMMENCER AU PROCHAIN TOUR');
          }
        });
      }
      catch (err) {
        const error = err as Error;
        console.log(error.message);
      }

      if (refresh_requests.length > 0) {
        console.log('ON REFRESH');
        console.log(refresh_requests);
        queryClient.invalidateQueries(...refresh_requests);
      }
      console.log('ON RECAPITULE CE QU‘ON A REUSSI A SYNCHRO');
      if (unsynced_requests.length > 0) {
        console.log(`IL NOUS RESTE ${unsynced_requests.length} REQUETES NON SYNC, CA PART AU PROCHAIN TOUR`);
        const new_request_set = new Set(unsynced_requests.map(request => sha1(JSON.stringify(request.request)).toString()));
        const unsynced_queue = groupBy(unsynced_requests, 'priority');

        setSyncing(false);
        return updateQueue(unsynced_queue, new_request_set);
      }
      console.log('C‘EST GOOD TOUT EST SYNCHRO !');
      emptyQueue();
      setSyncing(false);
    }

    if (online && !isEmpty(requests_queue)) {
      console.log(`ON A DES REQUETES A SYNCHRONISER`);
      console.log(requests_queue);
      setSyncing(true);
      syncNetwork();
    }
  }, [online, requests_queue, emptyQueue, updateQueue, queryClient, setSyncing]);
}
