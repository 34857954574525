import create from 'zustand';
import { persist } from 'zustand/middleware'

import { User } from 'types/user';

const default_user: User = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  access_token: '',
  refresh_token: '',
  can_order: true,
  avatar_url: '',
  type: 'pro',
  role: 'user',
  gender: 'male',
  fk_organisation_id: 0,
  has_changed_password: true,
  terms_status: null,
  password: ''
};

type StoreUser = User & {
  setUser: (user: User) => void,
  reset: () => void,
  acceptTerms: () => void,
  refuseTerms: () => void,
  updateCanOrder: (can_order: boolean) => void,
  refreshCredentials: (access_token: string, refresh_token: string) => void,
  updateProfile: (profile: Pick<User, 'first_name' | 'last_name' | 'phone_number' | 'role'>) => void,
  setPasswordChanged: () => void,
}

export const useUserStore = create<StoreUser>()(persist(set => ({
  ...default_user,
  setUser: user => set(state => ({ ...state, ...user })),
  reset: () => set({ ...default_user }),
  acceptTerms: () => set({ terms_status: 'accepted' }),
  refuseTerms: () => set({ terms_status: 'refused' }),
  updateCanOrder: (can_order: boolean) => set({ can_order }),
  refreshCredentials: (access_token, refresh_token) => set({ access_token, refresh_token }),
  updateProfile: profile => set({ ...profile }),
  setPasswordChanged: () => set({ has_changed_password: true }),
}), { name: 'user-store' }));
