import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faExpandAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';

import styles from './final-panel.module.css';
import classNames from 'classnames';

export type FinalPanelProps = {
  title: React.ReactNode,
  body: React.ReactNode,
  link?: string,
  is_panel_visible: boolean,
  with_header_shadow?: boolean,
  closePanel?: () => void
}

const FinalPanel = ({ title, body, is_panel_visible, with_header_shadow, link, closePanel }: FinalPanelProps) => {
  const navigate = useNavigate();
  const onClick = React.useCallback(() => {
    if (closePanel) {
      closePanel();
    }
    navigate(link!);
  }, [link, closePanel, navigate]);

  return <div className={styles['panel']}>
    { is_panel_visible
      &&
      <React.Fragment>
        <div className={classNames({
          [styles['panel-header']]: true,
          [styles['panel-header-shadow']]: with_header_shadow,
        })}>
          { link
            &&
            <div className={styles['panel-controller']}>
              <div className={styles['panel-link']} onClick={onClick}>
                <button className={styles['panel-link-button']} onClick={onClick}>
                  <span className={styles['panel-link-button-icon-wrapper']}>
                    <FontAwesomeIcon icon={faExpandAlt} className={styles['panel-link-button-icon']} />
                  </span>
                  <span className={styles['panel-link-button-extended-text']}>Agrandir la vue</span>
                </button>
                Agrandir la vue
              </div>
              { closePanel
                ?
                <FontAwesomeIcon icon={faTimes} className={styles['close-icon']} onClick={closePanel} />
                :
                <FontAwesomeIcon icon={faChevronLeft} className={styles['close-icon']} onClick={() => navigate(-1)} />
              }
            </div>
          }
          <div className={styles['panel-header-content']}>
            <div className={styles['panel-header-title']}>
              {title}
            </div>
            { !link
              &&
              <React.Fragment>
                { closePanel
                  ?
                  <FontAwesomeIcon icon={faTimes} className={styles['close-icon']} onClick={closePanel} />
                  :
                  <FontAwesomeIcon icon={faChevronLeft} className={styles['close-icon']} onClick={() => navigate(-1)} />
                }
              </React.Fragment>
            }
          </div>
        </div>
        <div className={styles['panel-body']}>
          {body}
        </div>
      </React.Fragment>
    }
  </div>
};

export default FinalPanel;
