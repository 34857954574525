import { SelectOptionExtended } from "./select";

export const activity_areas = ['property-managment', 'bar-restaurant', 'sport', 'hostel', 'office', 'retail', 'cleaning', 'manufacturing', 'education', 'other'] as const;
export const sub_activity_areas = {
  'bar-restaurant': ['bar', 'fast-food', 'gourmet', 'cold-food', 'dark-kitchen', 'other'],
  'sport': ['fitness', 'sports-facility', 'other'],
  'office': ['coworking', 'public-premises', 'work-premises', 'other'],
  'retail': ['eating', 'agency', 'clothing', 'florist', 'leisure', 'other'],
  'education': ['kindergarden', 'high-school', 'university', 'other']
} as const;

export const location_zones = ['kitchen/food', 'dining-room/bar', 'offices/meeting-rooms', 'sanitary', 'other-public-reception', 'corridors/other', 'technical-area', 'storage-area'] as const;

export type EnvironmentConfig = {
  api: {
    protocol: string,
    url: string
  },
  algolia: {
    zone_index: string,
    masterwork_index: string,
    jack_index: string,
    jack_enterprise_index: string,
    material_index: string,
    apparatus_index: string,
    application_id: string,
    search_api_key: string,
    organisation_index: string,
    solutions_index: string,
    manufacturer_index: string
  },
  rocket_chat_api: {
    baseURL: string,
  },
  mix_panel: {
    token: string,
    server_url: string,
  },
  firebase: {
    global: {
      apiKey: string,
      authDomain: string,
      databaseURL: string,
      projectId: string,
      storageBucket: string,
      messagingSenderId: string,
      appId: string,
      measurementId: string
    },
    push: {
      vapidKey: string
    }
  },
  offline: {
    sync_timeout: number,
    default_priority: number,
    max_retries: number
  },
  can_order_refresh_delay: number,
  terms_url: string,
  jack_terms_url: string,
  faq_url: string,
  electronic_signature_threshold: number,
  ops: SelectOptionExtended[],
  accounts: SelectOptionExtended[],
  sales: SelectOptionExtended[],
  prevent_offer_id: number,
  trial_duration: number,
  stats_default_orga: string,
  stats_default_address: string,
  activity_areas: typeof activity_areas,
  sub_activity_areas: typeof sub_activity_areas,
  history_stats_organisations_blacklist: number[],
  cartography: {
    state_step_usage_master_works: number[],
    degraded_operation_step_master_works: number[]
  },
  zones: typeof location_zones,
  android: {
    playstore_url: string,
    init_url: string
  },
  stripe: {
    publishable_key: string,
    jack_account_url: string,
    payment_intent_url: string,
  },
  sinao: {
    account_name: string
  }
};

export type GlobalConfig = {
  development: EnvironmentConfig,
  remote: EnvironmentConfig,
  integration: EnvironmentConfig,
  production: EnvironmentConfig,
  demo: EnvironmentConfig
};
