import * as React from 'react';
import { Navigate, useLocation, useNavigate, useMatch, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { fetchAndActivate } from 'firebase/remote-config';
import get from 'lodash/get';

import { refreshCanOrder, registerDevice } from 'api/users';
import { User } from 'types/user';
import { refreshJackMe } from 'api/jacks';
import { useAppStore } from 'components/hooks/stores/app';
import { useUserStore } from 'components/hooks/stores/user';
import {useOrganisationStore} from 'components/hooks/stores/organisation';
import { useJackStore } from 'components/hooks/stores/jack';
import useCanOrder from 'components/hooks/can-order';
import useRocketChat from 'components/hooks/livechat';
import useFirebase from 'components/hooks/firebase';
import useVersionCheck from 'components/hooks/version-check';
import useOfflineSync from 'components/hooks/offline-sync';
import usePasswordReset from 'components/hooks/password-reset';
import { JackRoutes, OpsRoutes, UserRoutes } from './routes';

function bypassZustand(): { initial_logged_in: boolean; initial_user: User } {
  let initial_logged_in = false;
  let initial_user = undefined;
  const app_store = localStorage.getItem('app-store');
  const user_store = localStorage.getItem('user-store');

  if (app_store) {
    const app_state = JSON.parse(app_store);

    initial_logged_in = get(app_state, 'state.is_logged_in', false);
  }

  if (user_store) {
    const user_state = JSON.parse(user_store);

    initial_user = user_state.state;
  }
  return { initial_logged_in, initial_user };
}

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('checkout-success');
  const [searchParams, setSearchParams] = useSearchParams();
  const need_password_reset = usePasswordReset();
  const has_order_issue = useCanOrder();
  const { initial_logged_in, initial_user } = bypassZustand(); // See https://github.com/pmndrs/zustand/issues/300
  const is_logged_in = useAppStore(app => app.is_logged_in);
  const user = useUserStore();
  const organisation = useOrganisationStore();
  const { kyc, invoice_handled } = useJackStore(jack => ({ kyc: jack.kyc, invoice_handled: jack.invoice_handled }));
  const current_user = user.id !== 0 ? user : initial_user; // See https://github.com/pmndrs/zustand/issues/300
  const previous_location = React.useRef<string>();
  const refresh = {
    pro: refreshCanOrder,
    ops: refreshCanOrder,
    jack: refreshJackMe,
  };

  // Web and Android push notification registration
  useFirebase(is_logged_in, current_user, registerDevice);
  // Version Check (Android)
  useVersionCheck(is_logged_in);
  // Setup RocketChat live widget and store
  useRocketChat(is_logged_in, current_user);
  // Setup MixPanel
  // useMixPanel(is_logged_in, current_user);
  //Setup Offline Sync
  useOfflineSync();

  async function onRouteChanged() {
    // todo:  move to a better part
    if (is_logged_in) {
      const justCheckedOutWithSuccess = searchParams.get('checkout-success') === 'true';
      if (justCheckedOutWithSuccess) {
        user.updateCanOrder(true);
        organisation.updateHasValidPaymentMethod(true);
        // await refresh[current_user.type]();
      }
      const expire_date = localStorage.getItem('can_order_refresh');
      // background refresh logic
      if (!expire_date || moment().isAfter(moment(Number(expire_date)))) {
        // Can-order auto refresh
        console.log('Refreshing can order', current_user);
        await refresh[current_user.type]();
      }
    }
  }

  React.useEffect(() => {
    onRouteChanged();
    // Third party setup
    // if (is_logged_in && import.meta.env.MODE === 'production') {
    //   //Stonly
    //   window.stonlyTrack && window.stonlyTrack('identify', '', {
    //     'user_type': current_user.type,
    //     'user_role': current_user.role,
    //     'display_mode': display_mode,
    //     'version': packageInfo.version,
    //   });
    // }
    /* eslint-disable-next-line */
  }, [is_logged_in]);

  // React.useEffect(() => {
  //   if (
  //     is_logged_in &&
  //     user.type === 'jack' &&
  //     // Stripe onboarding not complete
  //     (invoice_handled && kyc !== 'validated') && JSON.parse(sessionStorage.getItem('onboarding-skipped') || 'false') === false
  //     // Without Stripe onboarding not complete
  //     || (!invoice_handled && user.terms_status !== 'accepted')
  //   ) {
  //     navigate('/jack/onboarding');
  //   }
  // }, [is_logged_in, kyc, user.type, navigate]);

  React.useEffect(() => {
    if (previous_location.current && previous_location.current !== location.pathname) {
      onRouteChanged();
    }
    previous_location.current = location.pathname;
    /* eslint-disable-next-line */
  }, [location]);

  if (need_password_reset) {
    return need_password_reset;
  }

  if (has_order_issue) {
    return has_order_issue;
  }

  if (!initial_logged_in && !is_logged_in) {
    let login_path = '/login';

    if (location.pathname !== '/') {
      login_path = `${login_path}?destination=${encodeURIComponent(`${location.pathname}${location.search}`)}`;
    }
    return <Navigate to={login_path} replace />;
  }

  if (current_user.type === 'jack') {
    return <JackRoutes />;
  }
  if (current_user.type === 'ops') {
    return <OpsRoutes />;
  }
  return <UserRoutes />;
};

export default Auth;
