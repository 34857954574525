import * as React from 'react';

import { AnimatedPanelProps } from 'components/widgets/panel/animated-panel';

type PanelContextProps = {
  state: Omit<AnimatedPanelProps, 'closePanel'>,
  dispatch: React.Dispatch<any>
};

export const PanelContext = React.createContext({
  state: {
    is_panel_visible: false,
    sections: [],
    title: '',
    link: ''
  },
  dispatch: () => {}
} as PanelContextProps);
